import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FilterQueryBuilder from '../FilterQueryBuilder/FilterQueryBuilder';
import { RuleGroupType } from '@react-querybuilder/ts';

interface FilterQueryBuilderModalProps {
  isOpen: boolean;
  queryBuilderObject?: RuleGroupType;
  onClose: () => void;
  onSaveFilters: (query: RuleGroupType | undefined) => void;
}

interface FilterQueryBuilderModalState {
  query: RuleGroupType | undefined;
}

class FilterQueryBuilderModal extends Component<FilterQueryBuilderModalProps, FilterQueryBuilderModalState>
{
  constructor(props: FilterQueryBuilderModalProps) {
    super(props);
    this.state = {
      query: undefined,
    }
  }

  componentDidMount() {
    if (this.props.queryBuilderObject) {
      this.setState({
        query: this.props.queryBuilderObject,
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<FilterQueryBuilderModalProps>) {
    const { queryBuilderObject } = this.props;

    if (queryBuilderObject && queryBuilderObject !== prevProps.queryBuilderObject) {
      this.setState({
        query: queryBuilderObject,
      })
    }
  }

  handleSaveFilters = () => {
    const { onSaveFilters } = this.props;
    const { query } = this.state;

    onSaveFilters(query);
  }

  render() {
    const { isOpen, queryBuilderObject, onClose } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        centered
        style={{ maxWidth: "650px" }}
      >
        <ModalHeader>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label>Magic Filters</label>
            <button
              onClick={onClose}
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="keyword-close-button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <FilterQueryBuilder
            defaultQuery={queryBuilderObject}
            onQueryChange={(query: RuleGroupType) => this.setState({ query })}
          />
        </ModalBody>
        <ModalFooter>
          <button
            onClick={this.handleSaveFilters}
            type="button"
            className="recording-modal__close-button btn-secondary"
            style={{ width: "100px" }}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FilterQueryBuilderModal;
