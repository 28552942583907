import { sleep } from "../../utilities/helperFunctions";

const FIVE_MB = 5 * 1024 * 1024;
const URLS_PAGE = 50;
export const firstPartFailedError = new Error('First part of recording failed to upload');
const partFailedError = new Error('Part of recording failed to upload');

export async function streamToS3Urls(mediaRecorder, urls, getMoreUrls) {
  let currentUrlIndex = 0;
  const partPromises = [];

  let onRecordingEnded;
  const recordingEnded = new Promise(resolve => onRecordingEnded = resolve);
  const fiveMinutesAfterRecordingEnded = recordingEnded.then(() => sleep(300 * 1000));

  let urlsAvailable = urls.length;

  // TODO: if uploading to undefined urls those parts are lost...
  const appendUrlsIfNeeded = async () => {
    if (urls.length - currentUrlIndex >= URLS_PAGE / 2) {
      return;
    }
    const urlsAvailableAtStart = urlsAvailable;
    const { data: newUrls } = await getMoreUrls(
      urlsAvailable + 1,
      Math.max(
        URLS_PAGE,
        URLS_PAGE + (currentUrlIndex - urlsAvailable) * 4
      ),
    );
    newUrls.forEach((url, i) => urls[urlsAvailableAtStart + i] = url);
    urlsAvailable = Math.max(urlsAvailable, urlsAvailableAtStart + newUrls.length);
  };

  const sendPart = body => {
    const partIndex = currentUrlIndex++;
    const fetchFunc = () => {
      if (!urls[partIndex]) {
        throw new Error('URL not available for this part');
      }
      return fetch(urls[partIndex], {
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        body,
      });
    };
    partPromises[partIndex] = partIndex > 0
      ? Promise.race([
        retry(fetchFunc),
        fiveMinutesAfterRecordingEnded.then(() => Promise.reject(partFailedError)),
      ])
      : Promise.race([
        retry(fetchFunc, { retryTimes: Infinity, maxWaitSeconds: 30 }),
        fiveMinutesAfterRecordingEnded.then(() => Promise.reject(firstPartFailedError)),
      ]);
    retry(appendUrlsIfNeeded);
    return partPromises[partIndex];
  };

  try {
    new ReadableStream({ type: 'bytes' });
  } catch (e) {
    console.debug('Binary stream not supported');
    let blobChunks = [], totalSize = 0;

    mediaRecorder.ondataavailable = event => {
      blobChunks.push(event.data);
      totalSize += event.data.size;

      if (totalSize >= FIVE_MB) {
        sendPart(new Blob(blobChunks, { type: blobChunks[0].type }));
        blobChunks = [];
        totalSize = 0;
      }
    };

    await new Promise((resolve, reject) => {
      mediaRecorder.onstop = () => {
        if (totalSize > 0) {
          sendPart(new Blob(blobChunks, { type: blobChunks[0].type }));
        }
        onRecordingEnded();
        resolve();
      };

      mediaRecorder.onerror = (e) => {
        onRecordingEnded();
        reject(e);
      };

      mediaRecorder.start(1000);
    });

    const responses = await Promise.all(partPromises);
    return responses.map(response => response.headers.get('ETag').replace(/"/g, ''));
  };

  console.debug('Binary stream supported');
  const binaryStream = new ReadableStream({
    start: (controller) => {
      mediaRecorder.ondataavailable = async (event) => {
        controller.enqueue(new Uint8Array(await event.data.arrayBuffer()));
      };

      mediaRecorder.onstop = () => {
        controller.close();
      };

      mediaRecorder.start(1000);
    },
    // @ts-ignore
    type: 'bytes',
  });

  let buffer = new ArrayBuffer(FIVE_MB);
  let dataArray = new Uint8Array(buffer);
  let byteOffset = 0;

  return new Promise((resolve, reject) => {
    const writableStream = new WritableStream({
      write(chunk) {
        if (byteOffset + chunk.length <= FIVE_MB) {
          dataArray.set(chunk, byteOffset);
          byteOffset += chunk.length;
          return;
        }

        dataArray.set(chunk.slice(0, FIVE_MB - byteOffset), byteOffset);
        sendPart(dataArray);

        buffer = new ArrayBuffer(FIVE_MB);
        dataArray = new Uint8Array(buffer);
        dataArray.set(chunk.slice(FIVE_MB - byteOffset), 0);
        byteOffset = byteOffset + chunk.length - FIVE_MB;
      },

      async close() {
        if (byteOffset > 0) {
          dataArray = dataArray.slice(0, byteOffset);
          sendPart(dataArray);
        }
        try {
          onRecordingEnded();
          const responses = await Promise.all(partPromises);
          const etags = responses.map(response => response.headers.get('ETag').replace(/"/g, ''));
          resolve(etags);
        } catch (e) {
          reject(e);
        }
      },

      abort(reason) {
        reject(reason);
      },
    });

    binaryStream.pipeTo(writableStream);
  });
};

export function detectWhenDisabled(stream, callback) {
  stream.getTracks().forEach(
    track => track.addEventListener('ended', () => {
      if (stream.getTracks().every(track => track.readyState === 'ended')) {
        callback(stream);
      }
    })
  );
}

async function retry(asyncFunc, { retryTimes = 15, maxWaitSeconds = Infinity } = {}) {
  let attemptNum = 1;
  while (true) {
    try {
      return await asyncFunc();
    } catch (e) {
      if (++attemptNum > retryTimes) break;
      await sleep(Math.min(maxWaitSeconds, attemptNum * attemptNum) * 1000);
    }
  }
}

export function getAudioLevelsForStream(stream, callback) {
  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 256;
  const bufferLength = analyser.frequencyBinCount;
  source.connect(analyser);
  let enabled = true;

  (function getAudioLevels() {
    if (!enabled) return;
    const dataArray = new Uint8Array(bufferLength);
    analyser.getByteFrequencyData(dataArray);
    let sum = 0;
    for (let i = 0; i < bufferLength; i++) {
      sum += dataArray[i];
    }
    const averageLevel = sum / bufferLength;

    // 150 is relatively loud, setting that as maximum here
    const audioLevel = Math.max(0, Math.min(1, averageLevel / 150));
    callback (audioLevel);
    requestAnimationFrame(getAudioLevels);
  })();

  return (streamToDetach) => {
    if (streamToDetach !== stream) return;
    callback(0);
    enabled = false;
  };
}

export function createSingleAudioStream(firstInputStream) {
  const audioContext = new AudioContext({ sinkId: { type: 'none' }});
  const merger = audioContext.createChannelMerger(1); // 1 channel (mono)
  const sources = [];

  const source = audioContext.createMediaStreamSource(firstInputStream);
  source.connect(merger);
  sources.push(source);
  const destination = audioContext.createMediaStreamDestination();
  merger.connect(destination);

  const output = new MediaStream();
  output.addTrack(
    destination.stream.getAudioTracks()[0]
  );

  const add = stream => {
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(merger);
    sources.push(source);
  };

  const remove = stream => {
    const source = sources.find(({ mediaStream }) => mediaStream.id === stream?.id);
    if (source) {
      try {
        source.disconnect();
      } catch (error) {
        console.error(error);
      }
      sources.splice(sources.indexOf(source), 1);
    }
  };

  return { output, add, remove };
}

export const mapReceiversToParticipants = (receivers) => {
  return receivers.map((receiver) => ({
    id: receiver.id,
    firstName: receiver.first_name || '',
    lastName: receiver.last_name || '',
    email: receiver.email || '',
    phone: receiver.phone || '',
    isAddedAfter: receiver.isAddedAfter || null,
  }));
};
