import { getEndDatesForAPI, LAST_90_DAYS } from '../../services/utilities/utilservice';
import {
  GET_GLOBAL_FILTERS,
  GET_GLOBAL_FILTERS_SUCCESS,
  GET_GLOBAL_FILTERS_ERROR,
  EMPTY_DATE_FILTERS,
  FilterActionTypes,
  UPDATE_DASHBOARD_FILTERS_AFTER_EDIT,
  UPDATE_ALL_FILTERS_CAMPAIGN,
  RESET_FILTERS,
  SET_GLOBAL_FILTERS_LOADING, UPDATE_QUERY_BUILDER_FILTER,
} from "./actionTypes";
import { GlobalFilterState, GlobalFilterAction } from "./interface";
import { isEmpty } from 'lodash';
import { findFilterStateKey } from './actions';

const createFilterObject = (campaigns?: any) => {
  const { startdate, enddate } = getEndDatesForAPI(LAST_90_DAYS) || {};

  return {
  recordingTypes: [],
  campaigns: campaigns || [],
  meetingTypes: [],
  filterUsers: [],
  filterTeams: [],
  dealsStatus: [],
  callsOutcome: [],
  startDate: startdate as string,
  endDate: enddate as string,
  globalPeriod: LAST_90_DAYS,
}};

const initialState: GlobalFilterState = {
  ...createFilterObject(),
  loading: false,
  isForcedCampaigns: false,
  updateInProgress: true,
  forcedCampaigns: [],
  error: "",
  dashboardFilters: createFilterObject(),
  meetingFilters: createFilterObject(),
  recordingFilters: createFilterObject(),
  statFilters: createFilterObject(),
  inspirationFilters: createFilterObject(),
};

const globalFilters = (state = initialState, action: GlobalFilterAction) => {
  switch (action.type) {
    case GET_GLOBAL_FILTERS:
      break;
    case GET_GLOBAL_FILTERS_SUCCESS:
        state = {
          ...state,
          campaigns: action.payload.campaigns ? action.payload.campaigns : state.campaigns,
          filterUsers: action.payload.users ? action.payload.users : state.filterUsers,
          filterTeams: action.payload.teams ? action.payload.teams : state.filterTeams,
          meetingTypes: action.payload.meetingTypes ? action.payload.meetingTypes : state.meetingTypes,
          recordingTypes: action.payload.recordingTypes ? action.payload.recordingTypes : state.recordingTypes,
          dealsStatus: action.payload.dealsStatus ? action.payload.dealsStatus : state.dealsStatus,
          callsOutcome: action.payload.callsOutcome ? action.payload.callsOutcome : state.callsOutcome,
          loading: false,
          dashboardFilterLoading: false,
        };

      break;
    case GET_GLOBAL_FILTERS_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    case FilterActionTypes.UPDATE_DASHBOARD_FILTERS:
      state = {
        ...state,
        loading: false,
        dashboardFilters: {
          ...state.dashboardFilters,
          [action.payload.filterType]: action.payload.value,
        },
      };
      break;
    case FilterActionTypes.UPDATE_MEETING_FILTERS:
      state = {
        ...state,
        meetingFilters: {
          ...state.meetingFilters,
          [action.payload.filterType]: action.payload.value,
        },
      };
      break;
    case FilterActionTypes.UPDATE_RECORDING_FILTERS:
      state = {
        ...state,
        recordingFilters: {
          ...state.recordingFilters,
          [action.payload.filterType]: action.payload.value,
        },
      };
      break;
    case FilterActionTypes.UPDATE_STATS_FILTERS:
      state = {
        ...state,
        statFilters: {
          ...state.statFilters,
          [action.payload.filterType]: action.payload.value,
        },
      };
      break;
    case FilterActionTypes.UPDATE_INSPIRATION_FILTERS:
      state = {
        ...state,
        inspirationFilters: {
          ...state.inspirationFilters,
          [action.payload.filterType]: action.payload.value,
        },
      };
      break;
    case EMPTY_DATE_FILTERS:
      const filterKey = `${action.payload}Filters`;
      state = {
        ...state,
        [filterKey]: {
          ...state[filterKey],
          startDate: "",
          endDate: "",
        },
      };
      break;
    case UPDATE_DASHBOARD_FILTERS_AFTER_EDIT:
      const isPayloadEmpty = Object.keys(action.payload).length === 0;
      if (isPayloadEmpty) {
        state = {
          ...state,
          loading: false,
          dashboardFilters: createFilterObject(state.forcedCampaigns),
        };
      } else {
        state = {
          ...state,
          loading: false,
          dashboardFilters: {
            ...createFilterObject(),
            ...action.payload,
            campaigns: !isEmpty(state.forcedCampaigns) ? state.forcedCampaigns : (action.payload.campaigns ?? []),
          },
        };
      }
      break;
    case UPDATE_ALL_FILTERS_CAMPAIGN:
      return {
        ...state,
        updateInProgress: false,
        forcedCampaigns: action.payload,
        isForcedCampaigns: isEmpty(action.payload) ? false : true,
        dashboardFilters: { ...state.dashboardFilters, campaigns: isEmpty(action.payload) ? state.dashboardFilters.campaigns : action.payload },
        meetingFilters: { ...state.meetingFilters, campaigns: action.payload },
        recordingFilters: { ...state.recordingFilters, campaigns: action.payload },
        statFilters: { ...state.statFilters, campaigns: action.payload },
        inspirationFilters: { ...state.inspirationFilters, campaigns: action.payload },
        loading: false,
      };
    case RESET_FILTERS:
      return {
        ...state,
        dashboardFilters: createFilterObject(),
        isForcedCampaigns: false,
        filterUsers: [],
        filterTeams: [],
        recordingTypes: [],
        meetingTypes: [],
        dealsStatus: [],
        callsOutcome: [],
      };
    case SET_GLOBAL_FILTERS_LOADING:
      state = {
        ...state,
        loading: true,
      }
      break;
    case UPDATE_QUERY_BUILDER_FILTER: {
      const filterStateKey = findFilterStateKey(action.payload.filterType);
      state = {
        ...state,
        loading: false,
        [filterStateKey]: {
          ...state[filterStateKey], // Properly reference the existing state for the filter key
          queryBuilderObject: action.payload.queryBuilderObject,
        },
      };
      break;
    }
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default globalFilters;
