import React, { Component } from 'react';
import ArrowDown from '../ArrowDown/ArrowDown';

interface MagicFilterButtonProps {
  className: string;
  filtersCount?: number;
  isDisabled?: boolean;
  onClick: () => void;
}

class MagicFilterButton extends Component<MagicFilterButtonProps>
{
  render() {
    const { className, filtersCount, isDisabled, onClick } = this.props;

    const fCount = filtersCount && filtersCount > 0 ? filtersCount : null;

    return (
      <div
        className={className}
        style={{
          backgroundColor: '#FFFFFF',
          color: '#000000',
          cursor: 'pointer !important',
          padding: '10px 8px 3px 10px',
        }}
        onClick={!isDisabled ? onClick : () => {}}
      >
        <label
          style={{ marginLeft: '7px', display: 'flex', justifyContent: 'space-between' }}
        >
          <p style={{ fontWeight: 'bolder', margin: 0 }}>Magic Filters {fCount && `(${fCount})`}</p>
          <ArrowDown style={{ color: '#CCCCCC', fontWeight: 'normal !important', marginLeft: '5px' }} />
        </label>
      </div>
    )
  }
}

export default MagicFilterButton;
