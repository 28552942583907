import axios from 'axios';
import { urls } from '../../utilities/urls';
import { endpoints } from '../../utilities/endpoints';
import { commonheaders } from '../../utilities/apiConfig';
import { ResponseGenerator } from '../userservice';
import { ICalendarFilters } from '../../store/settings/Calendars/interface';

export async function getCalendarUpcomingEvents(filters?: ICalendarFilters) {
  try {
    const params = new URLSearchParams();

    if (filters?.search) {
      params.append('search', filters?.search);
    }

    if (filters?.global_user_ids && filters?.global_user_ids.length) {
      filters.global_user_ids.forEach((value, index) => {
        params.append('global_user_ids[]', String(value));
      })
    }

    if (filters?.global_team_ids && filters?.global_team_ids.length) {
      filters.global_team_ids.forEach((value, index) => {
        params.append('global_team_ids[]', String(value));
      })
    }

    if (filters?.global_meeting_types && filters?.global_meeting_types.length) {
      filters.global_meeting_types.forEach((value, index) => {
        params.append('global_meeting_types[]', String(value));
      })
    }

    if (filters?.current_page) {
      params.append('page_id', String(filters?.current_page));
    }

    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.calendars}/upcoming`,
      {
        // @ts-ignore
        headers: commonheaders,
        params
      },
    );

    return response?.data.data ?? [];
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getCalendarPastEvents(filters?: ICalendarFilters) {
  try {
    const params = new URLSearchParams();

    if (filters?.search) {
      params.append('search', filters?.search);
    }

    if (filters?.global_user_ids && filters?.global_user_ids.length) {
      filters.global_user_ids.forEach((value, index) => {
        params.append('global_user_ids[]', String(value));
      })
    }

    if (filters?.global_team_ids && filters?.global_team_ids.length) {
      filters.global_team_ids.forEach((value, index) => {
        params.append('global_team_ids[]', String(value));
      })
    }

    if (filters?.global_meeting_types && filters?.global_meeting_types.length) {
      filters.global_meeting_types.forEach((value, index) => {
        params.append('global_meeting_types[]', String(value));
      })
    }

    if (filters?.current_page) {
      params.append('page_id', String(filters?.current_page));
    }

    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.calendars}/past`,
      {
        // @ts-ignore
        headers: commonheaders,
        params
      },
    );

    return response?.data.data ?? [];
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function getEventByCalendarAndEvent(eventId: string) {
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}${endpoints.calendars}/events/${eventId}`,
      commonheaders
    );

    return response?.data.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}