import {cookies} from "../../services/utilities/utilservice";
import userBlue from "../../assets/images/dashboard/user-blue.svg";
import userGreen from "../../assets/images/dashboard/user-green.svg";
import userGray from "../../assets/images/dashboard/user-gray.svg";
import { Event, Colors } from './interface';
import { IMeetingTypes, NO_MEETING_TYPE } from "../../store/interface";
import { EmojiOutline } from "../../components/EmojiOutline";
import { Participant } from './interface';
import { adaptDateToTimezone } from "../../services/utilities/newUtilservice";

export const sparkleIcon = <EmojiOutline>✨</EmojiOutline>;

export const getColorClass = (color: Colors): string => {
  switch (color) {
    case Colors.PERSONAL:
      return "border-red";
    case Colors.GMAIL:
      return "border-green";
    case Colors.OUTLOOK:
      return "border-blue";
    default:
      return "border-green";
  }
};

export const formatDate = (dateString: string | null): string => {
  if (!dateString) {
    return "";
  }
  return dateString.replace(" ", "T");
};

export const getAuthToken = () => {
  const token = cookies.get("JWT_Token");
  return token ? token.replace(/^JWT\s*/, '') : null;
};

export const getParticipantData = (event: Event) => {
  const { participants, selected_owner, calendar_event_info } = event;

  if (!calendar_event_info) {
    return {
      sortedParticipants: [],
      sortedMembers: [],
      sortedGuests: [],
      selectedOrPotentialOwnerEmail: null,
      ownerOptions: [],
    }
  }

  const sortedParticipants = sortParticipants(participants, event);

  const selectedOrPotentialOwnerEmail = selected_owner || calendar_event_info?.potential_owners?.[0] || null;
  const sortedMembers = sortedParticipants.filter(
    participant => calendar_event_info?.participants?.[participant.email]?.type === 'Member'
  );
  const sortedGuests = sortedParticipants.filter(
    participant => calendar_event_info?.participants?.[participant.email]?.type !== 'Member'
  );
  const ownerOptions = sortedParticipants.filter(
    ({ email }) => email !== selected_owner && calendar_event_info?.participants?.[email]?.type === 'Member'
  );

  return {
    sortedParticipants,
    sortedMembers,
    sortedGuests,
    selectedOrPotentialOwnerEmail,
    ownerOptions,
  };
}

export const participantIcon = (email: string | null, event: Event) => {
  if (email === event?.organizer_email) {
    return <img style={{ transform: 'translateY(-1px)' }} src={userGreen} alt="Organizer" />;
  }
  if (event?.calendar_event_info?.participants?.[email!]?.type === 'Member') {
    return <img style={{ transform: 'translateY(-1px)' }} src={userBlue} alt="Coworker" />;
  }
  return !!email && <img style={{ transform: 'translateY(-1px)' }} src={userGray} alt="Participant" />;
};

export const ownerIcon = (email: string, selected_owner: string | null, calendar_event_info: any) => {
  return <>
    {email === selected_owner && (
      <>
        {' '}
        <i
          className="fa fa-check"
          title="Selected by user"
          style={{ cursor: 'default' }}
        />
      </>
    )}
    {
      !selected_owner &&
      calendar_event_info?.potential_owners?.includes(email) && (
        <>
          {' '}
          <span title="Suggested by AI">{sparkleIcon}</span>
        </>
      )
    }
  </>;
};

export const getFullName = (email: string | null, event: Event) => {
  if (!email || !event) return email;

  if (email === event.organizer_email && event.organizer_name) {
    return event.organizer_name;
  }
  let matched = event.calendar_event_info?.participants?.[email]?.name
    || Object.values(event.participants || []).find(({ email: e }) => e === email)?.name

  return matched || email;
};

export const getFullNameWithEmailInBrackets = (email: string | null, event: Event) => {
  const fullName = getFullName(email, event);
  if (fullName === email) return email;
  return <span>
    <span style={{ fontWeight: 500 }}>{fullName}</span> <span>&lt;{email}&gt;</span>
  </span>;
};

export const getNameWithTooltip = (email: string | null, event: Event) => {
  const name = getFullName(email, event);
  if (name && name !== email) {
    return <span title={email!}>{name}</span>
  }
  return email;
};

export const getMeetingType  = (event: Event, allMeetingTypes: IMeetingTypes[]) => {
  return event.selected_meeting_type_id
    ? (
      <>
        {allMeetingTypes.find(({ id }) => id === event.selected_meeting_type_id)?.name}
        {' '}
        <i className="fa fa-check" style={{ cursor: 'default' }} title="Selected by user" />
      </>
    ) : (
      event.suggested_meeting_type_id
      ? (
        <>
          {allMeetingTypes.find(({ id }) => id === event.suggested_meeting_type_id)?.name || NO_MEETING_TYPE}
          {' '}
          {event.suggested_meeting_type_id && <span title="Set by keyword match">{sparkleIcon}</span>}
        </>
      ) : NO_MEETING_TYPE
    )
};

export const sortParticipants = (participants: Participant[], event: Event) => {
  return Object.values(participants).sort((a, b) => {
    if (
      a.email === event.selected_owner
      || a.email === event.calendar_event_info?.potential_owners?.[0]
      || (
        event.calendar_event_info?.potential_owners?.includes(a.email)
        && !event.calendar_event_info?.potential_owners?.includes(b.email)
      )
      || (
        event.calendar_event_info?.participants?.[a.email]?.type === 'Member'
        && event.calendar_event_info?.participants?.[b.email]?.type !== 'Member'
      )
      || a.email === event.organizer_email
    ) {
      return -1;
    }
    return 0;
  });
};

export const formatDatetimeRange = (
  startTime: string | null,
  endTime?: string | null,
  timezone?: string,
) => {
  if (
    // TODO: this will be ok for now but needs to be more robust
    startTime?.substring(0, 10) !== endTime?.substring(0, 10)
    && startTime?.substring(11) === endTime?.substring(11)
  ) {
    return (
      <span>
        <span style={{ whiteSpace: 'nowrap' }}>{startTime?.substring(0, 10)}</span>
        <br />
        <span style={{ whiteSpace: 'nowrap' }}>(All day)</span>
      </span>
    );
  }

  const formattedStart = [
      adaptDateToTimezone(startTime!, timezone, "YYYY-MM-DD"),
      adaptDateToTimezone(startTime!, timezone, "HH:mm"),
    ];
  if (!endTime) {
    return formattedStart;
  }

  const formattedEnd = [
    adaptDateToTimezone(endTime, timezone, "YYYY-MM-DD"),
    adaptDateToTimezone(endTime, timezone, "HH:mm"),
  ];

  return (
    <span>
      <span style={{ whiteSpace: 'nowrap' }}>{formattedStart[0]}</span>
      <br />
      <span style={{ whiteSpace: 'nowrap' }}>
        {formattedStart[1]}
        {' - '}
        {formattedEnd[1]}
      </span>
    </span>
  );
}
