import {
  GET_AIRCALL_TOKEN,
  GET_AIRCALL_TOKEN_SUCCESS,
  GET_AIRCALL_TOKEN_ERROR,
  CREATE_AIRCALL_TOKEN,
  CREATE_AIRCALL_TOKEN_SUCCESS,
  CREATE_AIRCALL_TOKEN_ERROR,
  EDIT_AIRCALL_TOKEN,
  EDIT_AIRCALL_TOKEN_SUCCESS,
  EDIT_AIRCALL_TOKEN_ERROR,
  DELETE_AIRCALL_TOKEN,
  DELETE_AIRCALL_TOKEN_SUCCESS,
  DELETE_AIRCALL_TOKEN_ERROR,
} from './actionTypes';

import { IAircallToken, IAircallTokenData } from './interface';

export const getAircallToken = () => ({
  type: GET_AIRCALL_TOKEN,
  payload: null,
});

export const getAircallTokenSuccess = (aircallToken: IAircallToken|null) => ({
  type: GET_AIRCALL_TOKEN_SUCCESS,
  payload: aircallToken,
});

export const getAircallTokenError = (error: String | Object) => ({
  type: GET_AIRCALL_TOKEN_ERROR,
  payload: error,
});

export const createAircallToken = (aircallToken: IAircallToken) => ({
  type: CREATE_AIRCALL_TOKEN,
  payload: aircallToken,
});

export const createAircallTokenSuccess = (aircallToken: IAircallToken) => ({
  type: CREATE_AIRCALL_TOKEN_SUCCESS,
  payload: aircallToken,
});

export const createAircallTokenError = (error: String | Object) => ({
  type: CREATE_AIRCALL_TOKEN_ERROR,
  payload: error,
});

export const editAircallToken = (aircallToken: IAircallToken) => ({
  type: EDIT_AIRCALL_TOKEN,
  payload: aircallToken,
});

export const editAircallTokenSuccess = (aircallToken: IAircallToken) => ({
  type: EDIT_AIRCALL_TOKEN_SUCCESS,
  payload: aircallToken,
});

export const editAircallTokenError = (error: String | Object) => ({
  type: EDIT_AIRCALL_TOKEN_ERROR,
  payload: error,
});

export const deleteAircallToken = (id: string) => ({
  type: DELETE_AIRCALL_TOKEN,
  payload: id,
});

export const deleteAircallTokenSuccess = () => ({
  type: DELETE_AIRCALL_TOKEN_SUCCESS,
  payload: null,
});

export const deleteAircallTokenError = (error: String | Object) => ({
  type: DELETE_AIRCALL_TOKEN_ERROR,
  payload: error,
});

export const createAircallTokenFromData = (data: IAircallTokenData): IAircallToken => {
  return {
    id: data.id,
    apiId: data.api_id,
    apiToken: data.api_token,
    minDuration: data.min_duration,
  }
}
