import {
    GET_CALENDAR,
    GET_CALENDAR_SUCCESS,
    GET_CALENDAR_ERROR,
    GET_CALENDAR_UPCOMING_EVENTS,
    GET_CALENDAR_UPCOMING_EVENTS_SUCCESS,
    GET_CALENDAR_UPCOMING_EVENTS_ERROR,
    GET_CALENDAR_PAST_EVENTS,
    GET_CALENDAR_PAST_EVENTS_SUCCESS,
    GET_CALENDAR_PAST_EVENTS_ERROR,
    GET_MEETING_BOTS,
    GET_MEETING_BOTS_ERROR,
    GET_MEETING_BOTS_SUCCESS,
    GET_PROCESSING,
    GET_PROCESSING_ERROR,
    GET_PROCESSING_SUCCESS,
    UPDATE_CALENDAR_UPCOMING_EVENT,
} from "./actionTypes";
import { Event } from '../../../pages/Calendars/interface';

/**
 * State
 */
export interface ICalendarPreview {
    id: string;
    user_name: string;
    event_id: string;
    event_name: string;
    type: string;
    organizer: string;
    participants: string[];
    createdAt: string;
    unique_md5?: string;
    reason?: string;
    location?: string;
    video_url?: string;
    start_time?: string;
};

export interface ICalendarEventParticipant {
    email: string;
    name?: string;
    first_name?: string;
    last_name?: string;
    company?: string;
}

export interface IPaginatedUpcomingEventData {
    data?: Event[];
    current_page?: number;
    last_page?: number;
    results_per_page?: number;
    total_results?: number;
}

export interface IPaginatedPastEventData {
    data: Event[];
    current_page?: number;
    last_page?: number;
    results_per_page?: number;
    total_results?: number;
}

export const DEFAULT_PAGE = 1;

export interface ICalendarUpcomingEvent {
    calendarId: string,
    calendarTitle: string,
    eventId: string,
    state: string,
    title: string,
    meetingHash: string,
    videoUrl: string,
    startTime: string,
    organizerName: string | null,
    organizerEmail: string | null,
    endTime: string | null,
    eventModifiedAt: string | null,
    description: string | null,
    participants: ICalendarEventParticipant[] | null,
}

export interface IMeetingBot {
    id: string;
    event_name: string;
    created_at: string;
}

export interface IProcessing {
    id: string;
    event_name: string;
    description: string;
    created_at: string;
}

export interface ICalendarFilters {
    search?: string;
    calendar_ids?: string[];
    global_user_ids?: string[];
    global_team_ids?: string[];
    global_meeting_types?: string[];
    current_page?: number;
}

export interface ICalendarFiltersVisibility {
    showCalendars?: boolean;
    showTeams?: boolean;
    showUsers?: boolean;
    showMeetingTypes?: boolean;
}

export interface CalendarState {
    success: String;
    error: String | Object;
    loader: Boolean;
    calendars: ICalendarPreview[] | null;
    meetingBots: IMeetingBot[] | null;
    processing: IProcessing[] | null;
    upcomingEvents: IPaginatedUpcomingEventData | null;
    upcomingEventsError: String | Object;
    isLoadingUpcomingEvents: boolean;
    pastEvents: IPaginatedPastEventData | null;
    pastEventsError: String | Object;
    isLoadingPastEvents: boolean;
}

/**
 * Actions
 */

export type getCalendars = {
    type: typeof GET_CALENDAR;
    payload: {
        userId: Number;
    }
};

export type getCalendarsSuccess = {
    type: typeof GET_CALENDAR_SUCCESS;
    payload: ICalendarPreview[];
};

export type getCalendarsError = {
    type: typeof GET_CALENDAR_ERROR;
    payload: String | Object;
};

export type getCalendarUpcomingEvents = {
    type: typeof GET_CALENDAR_UPCOMING_EVENTS;
    payload: ICalendarFilters | undefined;
};

export type getCalendarUpcomingEventsSuccess = {
    type: typeof GET_CALENDAR_UPCOMING_EVENTS_SUCCESS;
    payload: IPaginatedUpcomingEventData;
};

export type getCalendarUpcomingEventsError = {
    type: typeof GET_CALENDAR_UPCOMING_EVENTS_ERROR;
    payload: String | Object;
};

export type getCalendarPastEvents = {
    type: typeof GET_CALENDAR_PAST_EVENTS;
    payload: ICalendarFilters | undefined;
};

export type getCalendarPastEventsSuccess = {
    type: typeof GET_CALENDAR_PAST_EVENTS_SUCCESS;
    payload: IPaginatedPastEventData;
};

export type getCalendarPastEventsError = {
    type: typeof GET_CALENDAR_PAST_EVENTS_ERROR;
    payload: String | Object;
};

export type updateCalendarUpcomingEvent = {
    type: typeof UPDATE_CALENDAR_UPCOMING_EVENT;
    payload: { id: string, key: keyof ICalendarUpcomingEvent, value: any };
};

export type getMeetingBots = {
    type: typeof GET_MEETING_BOTS;
    payload: {
        calendarId: string;
    };
}

export type getMeetingBotsSuccess = {
    type: typeof GET_MEETING_BOTS_SUCCESS;
    payload: IMeetingBot[];
}

export type getMeetingBotserror = {
    type: typeof GET_MEETING_BOTS_ERROR;
    payload: String | Object;
}

export type getProcessing = {
    type: typeof GET_PROCESSING;
    payload: {
        calendarId: string;
    };
}

export type getProcessingSuccess = {
    type: typeof GET_PROCESSING_SUCCESS;
    payload: IProcessing[];
}

export type getProcessingError = {
    type: typeof GET_PROCESSING_ERROR;
    payload: String | Object;
}

export type CalendarsAction =
  | getCalendars
  | getCalendarsSuccess
  | getCalendarsError
  | getCalendarUpcomingEvents
  | getCalendarUpcomingEventsSuccess
  | getCalendarUpcomingEventsError
  | getCalendarPastEvents
  | getCalendarPastEventsSuccess
  | getCalendarPastEventsError
  | updateCalendarUpcomingEvent
  | getMeetingBots
  | getMeetingBotsSuccess
  | getMeetingBotserror
  | getProcessing
  | getProcessingSuccess
  | getProcessingError;
