import moment from "moment-timezone";
import { countryCodes } from "./utilservice";

const setLocalStorage = (id: any, data: any) => {
  return localStorage.setItem(id, JSON.stringify(data));
};

const getLocalStorage = (id: any) => {
  if (id !== undefined) {
    const value = localStorage.getItem(id);
    if (value !== null) {
      return JSON.parse(localStorage.getItem(id) || "");
    } else {
      return null;
    }
  }
};

const adaptDateToTimezone = (
  dateString: string,
  country?: string,
  outputFormat = "YYYY-MM-DD HH:mm:ss",
  showLocalIfNotSet = false,
): string => {
  const utcMoment = moment.utc(dateString);

  let timezone;
  if (country) {
    const countryWithTimezone = countryCodes.find(
      (countryCode) => countryCode.name.toLowerCase() === country.toLowerCase()
    );

    if (countryWithTimezone) {
      timezone = countryWithTimezone.timezone;
      return utcMoment.tz(timezone).format(outputFormat);
    }
  }

  return (showLocalIfNotSet ? utcMoment.local() : utcMoment).format(outputFormat);
};

const adaptDateToUTC = (
  dateString: string,
  outputFormat = "YYYY-MM-DD HH:mm:ss"
): string => {
  const localMoment = moment(dateString);
  return localMoment.utc().format(outputFormat);
};

const limitStringCharacters = (string: string, maxCharacters = 70) => {
  if (string.length > maxCharacters) {
    return string.substring(0, maxCharacters) + "...";
  }

  return string;
};

const formatMinutesToHours = (value: number) => {
  return (value / 60).toFixed(1);
};

const formatSecondsToMinutes = (value: number) => {
  return (value / 60).toFixed(1);
};

const roundValue = (value: number) => {
  return value?.toFixed(0);
};

const roundValueWithOneDecimal = (value: number) => {
  return Number(value).toFixed(1);
};

const toTitleCase = (str: string) => {
  return str.replace(/\b(\w)/g, function(s) {
      return s.toUpperCase();
  });
}

export function formatSummary(summary: string) {
  // Check if the summary contains any double asterisks
  if (/\*\*/.test(summary)) {
    return formatSummaryWithAsterisks(summary);
  } else {
    return formatSummaryDefault(summary);
  }
}

export function formatSummaryDefault(summary: string) {
  let formattedSummary = summary.replace(/([^:]*:)(.*)/gm, '<span style="font-weight: bold !important;">$1</span><span style="font-weight: normal !important;">$2</span>');

  const formattedSummary2 = formattedSummary
    .replace(/\n(\d+\.)/g, '<div style="margin-bottom: 20px">$1')
    .replace(/\n+/g, '<div style="margin-bottom: 20px">');

  return { __html: formattedSummary2 };
}

export function formatSummaryWithAsterisks(summary: string, isReturnAsObject = true) {
  // Step 1: Split the summary into lines
  const lines = summary.split('\n');
  const processedLines: string[] = [];

  lines.forEach((line) => {
    // Step 2: Count pairs of asterisks (**)
    const asteriskPairCount = (line.match(/\*\*/g) || []).length;

    // Step 3: If the count of pairs is odd, remove all asterisks and wrap the line with ** at the start and end
    if (asteriskPairCount % 2 !== 0) {
      line = line.replace(/\*/g, ''); // Remove all asterisks in the line
      line = `**${line}**`; // Add a pair of asterisks at the start and end
    }

    // Step 4: Replace markdown bold notation with HTML bold tags
    line = line.replace(/\*\*(.*?)\*\*/g, '<span style="font-weight: bold !important;">$1</span>');

    // Step 5: Replace numbered items with div spacing for better formatting
    line = line.replace(/(\d+\.\s)/g, '<div style="margin-bottom: 20px"></div>$1');

    // Push the processed line to the results array
    processedLines.push(line);
  });

  // Join the processed lines back into a single formatted string
  let formattedSummary = processedLines.join('\n');

  // Replace any remaining single or double asterisks as cleanup
  formattedSummary = formattedSummary.replace(/\*{1,2}/g, '');

  // Replace newlines with divs for spacing between paragraphs
  formattedSummary = formattedSummary.replace(/\n+/g, '<div style="margin-bottom: 20px"></div>');

  // Return formatted HTML string as safe HTML
  return { __html: formattedSummary };
}

export {
  setLocalStorage,
  getLocalStorage,
  adaptDateToTimezone,
  adaptDateToUTC,
  limitStringCharacters,
  formatMinutesToHours,
  formatSecondsToMinutes,
  roundValue,
  roundValueWithOneDecimal,
  toTitleCase,
};
