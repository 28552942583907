import axios, { AxiosResponse } from 'axios';
import { ResponseGenerator } from '../../userservice';
import { urls } from '../../../utilities/urls';
import { endpoints } from '../../../utilities/endpoints';
import { commonheaders } from '../../../utilities/apiConfig';
import { IAircallToken } from "../../../store/settings/Aircall/interface";

export async function getAircallToken(): Promise<AxiosResponse<IAircallToken|null>>
{
  try {
    const response: ResponseGenerator = await axios.get(
      `${urls.baseApiURL}tokens/${endpoints.aircallToken}`,
      commonheaders
    );

    return response.data?.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function createAircallToken(aircallToken: IAircallToken): Promise<AxiosResponse<IAircallToken>>
{
  try {
    const response: ResponseGenerator = await axios.post(
      `${urls.baseApiURL}tokens/${endpoints.aircallToken}`,
      {
        api_id: aircallToken.apiId,
        api_token: aircallToken.apiToken,
        min_duration: aircallToken.minDuration,
      },
      commonheaders
    );

    return response.data?.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function editAircallToken(aircallToken: IAircallToken): Promise<AxiosResponse<IAircallToken>>
{
  try {
    const response: ResponseGenerator = await axios.put(
      `${urls.baseApiURL}tokens/${endpoints.aircallToken}/${aircallToken.id}`,
      {
        api_id: aircallToken.apiId,
        api_token: aircallToken.apiToken,
        min_duration: aircallToken.minDuration,
      },
      commonheaders
    );

    return response.data?.data ?? null;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}

export async function deleteAircallToken(id: string): Promise<AxiosResponse<null>>
{
  try {
    const response: ResponseGenerator = await axios.delete(
      `${urls.baseApiURL}tokens/${endpoints.aircallToken}/${id}`,
      commonheaders
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data;
    }
    return error;
  }
}
