import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import {
    getCalendarSuccess,
    getCalendarError,
    getCalendarUpcomingEventsSuccess,
    getCalendarUpcomingEventsError,
    getCalendarUpcomingEvents as getCalendarUpcomingEventsAction,
    getCalendarPastEventsSuccess,
    getCalendarPastEventsError,
    getCalendarPastEvents as getCalendarPastEventsAction,
    getMeetingBots as getMeetingBotsAction,
    getMeetingBotsError,
    getMeetingBotsSuccess,
    getProcessing as getProcessingAction,
    getProcessingError,
    getProcessingSuccess,
} from "./actions";

import {
    GET_CALENDAR,
    GET_CALENDAR_UPCOMING_EVENTS,
    GET_CALENDAR_PAST_EVENTS,
    GET_MEETING_BOTS,
    GET_PROCESSING,
} from "./actionTypes";

import { has } from "lodash";

import {
    getCalendars,
    getMeetingBots,
    getProcessing,
    ResponseGenerator,
} from "../../../services/userservice";

import {
    getCalendarUpcomingEvents,
    getCalendarPastEvents,
} from "../../../services/Calendar/api"
import { IPaginatedUpcomingEventData, IPaginatedPastEventData } from './interface';

function* getCalendarsHelper() {
    const response: ResponseGenerator = yield call(getCalendars);
    if (has(response, "error") || has(response, "errors")) {
        const error = response.error || response.errors;
        if (error) {
            yield put(getCalendarError(error));
        } else {
            yield put(getCalendarError("An error has occurred!!"));
        }
    } else {
        yield put(getCalendarSuccess(response.data));
    }
}

function* getCalendarUpcomingEventsHelper({ payload }: ReturnType<typeof getCalendarUpcomingEventsAction>) {
    const response: IPaginatedUpcomingEventData = yield call(getCalendarUpcomingEvents, payload);
    yield put(getCalendarUpcomingEventsSuccess(response));
}

function* getCalendarPastEventsHelper({ payload }: ReturnType<typeof getCalendarPastEventsAction>) {
    const response: IPaginatedPastEventData = yield call(getCalendarPastEvents, payload);
    yield put(getCalendarPastEventsSuccess(response));
}

function* getMeetingBotsHelper({ payload }: ReturnType<typeof getMeetingBotsAction>) {
    const response: ResponseGenerator = yield call(getMeetingBots, payload);
    if (has(response, "error") || has(response, "errors")) {
        const error = response.error || response.errors;
        if (error) {
            yield put(getMeetingBotsError(error));
        } else {
            yield put(getMeetingBotsError("An error has occurred!!"));
        }
    } else {
        yield put(getMeetingBotsSuccess(response.data));
    }
}

function* getProcessingHelper({ payload }: ReturnType<typeof getProcessingAction>) {
    const response: ResponseGenerator = yield call(getProcessing, payload);
    if (has(response, "error") || has(response, "errors")) {
        const error = response.error || response.errors;
        if (error) {
            yield put(getProcessingError(error));
        } else {
            yield put(getProcessingError("An error has occurred!!"));
        }
    } else {
        yield put(getProcessingSuccess(response.data));
    }
}

export function* watchGetCalendarsHelper() {
    yield takeEvery(GET_CALENDAR, getCalendarsHelper);
}

export function* watchGetCalendarUpcomingEventsHelper() {
    yield takeEvery(GET_CALENDAR_UPCOMING_EVENTS, getCalendarUpcomingEventsHelper);
}

export function* watchGetCalendarPastEventsHelper() {
    yield takeEvery(GET_CALENDAR_PAST_EVENTS, getCalendarPastEventsHelper);
}

export function* watchGetMeetingBotsHelper() {
    yield takeEvery(GET_MEETING_BOTS, getMeetingBotsHelper);
}

export function* watchGetProcessingHelper() {
    yield takeEvery(GET_PROCESSING, getProcessingHelper);
}

function* CalendarSaga() {
    yield all([
        fork(watchGetCalendarsHelper),
        fork(watchGetCalendarUpcomingEventsHelper),
        fork(watchGetCalendarPastEventsHelper),
        fork(watchGetMeetingBotsHelper),
        fork(watchGetProcessingHelper),
    ]);
}

export default CalendarSaga;
