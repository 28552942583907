import { IWidgetLayout } from "../../../components/Widgets/utils/IWidget";
import { FilterObject } from "../../../store/interface";
import { Filters } from "../../../store/layout/interface";
import { AGENT_PLAYBOOK_COMPONENT, IGlobalFilters } from '../../../components/Widgets/utils/constants';

export const titleForDeleteModal = "layout";

export interface WidgetOption {
  name: string;
  label: string;
  width: number;
  height: number;
  widgetIcon: string;
  minWidth?: number;
  minHeight?: number;
  addOneWidgetMultipleTimes?: boolean;
  options?: {
    filters: Filters;
    globalFilters: IGlobalFilters;
  };
  agentId?: number;
  description: string;
}

export const KEYWORD_STATUSES = {
  NOT_MENTIONED: 'not_mentioned',
  MENTIONED: 'mentioned',
}

export const STATISTIC_WIDGET_TYPES = {
  KEYWORD: "keyword",
  SENTIMENT: "sentiment",
  PRODUCT_ISSUES: "issue",
  PAIN_POINTS: "pain_point",
  NEW_NEEDS: "new_needs",
  POTENTIAL_UPSELLING: "potential_upselling",
  FOLLOW_UP_ACTIONS: "follow_up_actions",
  BUYER_QUESTION: "buyer_question",
  BUYER_OBJECTION: "buyer_objection",
  SELLER_QUESTION: "seller_question",
  IMPORTANCE: "importance",
  CUSTOMER_SENTIMENT: "customer_sentiment",
  TOTAL_QUESTION: "total_question",
  TOTAL_OBJECTION: "total_objection",
  BUYER_POSITIVITY: "buyer_positivity",
  ASYNC_PROMISE: "async_promise",
  ASYNC_UNPROFESSIONAL_BEHAVIOUR: "async_unprofessional_behaviour",
  AI_AGENT: 'async_magic_insight',
};

export const ANALYTICS_STATS_WIDGET_TYPES = {
  QUESTIONS: "avg_seller_questions_per_hour",
  SLIDES_SHOWN: "avg_slides_shown",
  AVG_WORD_PER_MINUTE: "avg_words_per_minute",
  DIRECT_SHARE_PERCENTAGE: "direct_share_percentage",
  FACE_2_FACE_PERCENTAGE: "face2face_percentage",
  MEETING_SCORE: "meeting_score",
  MEETING_ENGAGEMENT_PERCENTAGE: "meeting_engagement_percentage",
  SCREEN_SHARE_PERCENTAGE: "screen_share_percentage",
  DEALS: "deals",
  AVG_DIVISION: "avg_division",
  AVG_INTERRUPTIONS: "avg_interruptions",
  AVG_MEETING_TIME: "avg_meeting_time",
  AVG_SHIFTS_PER_MINUTE: "avg_shifts_per_minute",
  LONGEST_MONOLOGUE: "longest_monologue",
  LONGEST_USER_STORY: "longest_user_story",
  MEETINGS: "meetings",
  SCHEDULED_INTERACTIONS: "scheduled_interactions",
  TOTAL_MEETING_TIME: "total_meeting_time",
  CALL_OUTCOMES: "call_outcomes",
};

export const AGENT_WIDGET_TYPES = {
  playbook: 'playbook',
}

export const VARIABLE_WIDGET_TYPES = {
  variable: 'variable',
}

export const COMPARE_STATS_WIDGET_TYPE = 'compareWidget';

export const VARIABLE_COMPONENT = 'variable';

export const MEETING_STATS_WIDGETS = [
  {
    component: "sentenceStatsBuyerObjection",
    id: "sentenceStatsBuyerObjection:0",
    h: 2,
    w: 2,
    x: 0,
    y: 0,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsBuyerQuestion",
    id: "sentenceStatsBuyerQuestion:1",
    h: 2,
    w: 2,
    x: 2,
    y: 0,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsBuyerPositivity",
    id: "sentenceStatsBuyerPositivity:2",
    h: 2,
    w: 2,
    x: 4,
    y: 0,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "buyer",
        period: "monthly",
        chartType: "period",
        denominator: "average",
      },
    },
  },
  {
    component: "sentenceStatsBuyerObjection",
    id: "sentenceStatsBuyerObjection:3",
    h: 2,
    w: 2,
    x: 0,
    y: 2,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "monthly",
        chartType: "period",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsCustomerSentiment",
    id: "sentenceStatsCustomerSentiment:4",
    h: 2,
    w: 2,
    x: 2,
    y: 2,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsSellerQuestion",
    id: "sentenceStatsSellerQuestion:5",
    h: 2,
    w: 2,
    x: 4,
    y: 2,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "seller",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsBuyerObjection",
    id: "sentenceStatsBuyerObjection:6",
    h: 2,
    w: 2,
    x: 0,
    y: 4,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "buyer",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsPotentialUpselling",
    id: "sentenceStatsPotentialUpselling:7",
    h: 2,
    w: 2,
    x: 2,
    y: 4,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "",
        period: "monthly",
        chartType: "period",
        denominator: "",
      },
    },
  },
  {
    component: "talkListenRatio",
    id: "talkListenRatio:8",
    h: 2,
    w: 2,
    x: 4,
    y: 4,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsNewNeeds",
    id: "sentenceStatsNewNeeds:9",
    h: 2,
    w: 2,
    x: 0,
    y: 6,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "monthly",
        chartType: "period",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsPainPoints",
    id: "sentenceStatsPainPoints:10",
    h: 2,
    w: 2,
    x: 2,
    y: 6,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "monthly",
        chartType: "period",
        denominator: "",
      },
    },
  },
  {
    component: "wordsPerMinute",
    id: "wordsPerMinute:11",
    h: 2,
    w: 2,
    x: 4,
    y: 6,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsProductIssues",
    id: "sentenceStatsProductIssues:12",
    h: 2,
    w: 2,
    x: 0,
    y: 8,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "buyer",
        period: "monthly",
        chartType: "period",
        denominator: "",
      },
    },
  },
  {
    component: "avgInteractionTime",
    id: "avgInteractionTime:13",
    h: 2,
    w: 2,
    x: 2,
    y: 8,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "interactionTime",
    id: "interactionTime:14",
    h: 2,
    w: 2,
    x: 0,
    y: 10,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "screenShare",
    id: "screenShare:15",
    h: 2,
    w: 2,
    x: 2,
    y: 10,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "user",
        speakerType: "",
        period: "",
        chartType: "compare",
        denominator: "",
      },
    },
  },
  {
    component: "sentenceStatsFollowUpActions",
    id: "sentenceStatsFollowUpActions:16",
    h: 2,
    w: 2,
    x: 4,
    y: 10,
    minH: 2,
    minW: 2,
    options: {
      globalFilters: {
        recording_types: [],
        campaign_ids: [],
        global_meeting_types: [],
        global_user_ids: [],
        global_team_ids: [],
        calls_outcome: [],
        deals_status: [],
        global_period: "0",
      },
      filters: {
        groupBy: "value",
        speakerType: "",
        period: "monthly",
        chartType: "period",
        denominator: "",
      },
    },
  },
];

export const EMPTY_LAYOUT: IWidgetLayout = {
  name: "Empty dashboard",
  value: "empty",
  default: false,
  globalFilters: {},
  widgets: [],
};

export const AVAILABLE_TEMPLATE_LAYOUTS: IWidgetLayout[] = [
  {
    name: "Default dashboard",
    value: "meeting_stats",
    default: true,
    globalFilters: {},
    widgets: MEETING_STATS_WIDGETS,
  },
];

export const AVAILABLE_COMPONENTS: Array<{
  groupLabel: string;
  widgets: WidgetOption[];
}> = [
  {
    groupLabel: "Interaction",
    widgets: [
      {
        name: "interactionTime",
        widgetIcon: "fa fa-clock",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Interaction time",
        addOneWidgetMultipleTimes: true,
        description:
          "Interaction Time measures the overall duration of the meeting from start to finish, providing a comprehensive view of the time investment involved.",
      },
      {
        name: "numberOfInteractions",
        widgetIcon: "fa-brands fa-rocketchat",
        width: 2,
        height: 2,
        label: "Number of interactions",
        addOneWidgetMultipleTimes: true,
        description:
          "Number of Interactions tracks the total number of meetings that have taken place, offering a quantitative overview of the frequency of interactions.",
      },
      {
        name: "scheduledInteractions",
        widgetIcon: "fa-solid fa-calendar-lines-pen",
        width: 2,
        height: 2,
        label: "Scheduled interactions",
        addOneWidgetMultipleTimes: true,
        description:
          "The Scheduled Interactions widget is a comprehensive tool crafted to monitor and track scheduled meetings.",
      },
      {
        name: "avgInteractionTime",
        widgetIcon: "fa fa-hourglass-half",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Average interaction time",
        addOneWidgetMultipleTimes: true,
        description:
          "Average interaction time calculates the mean duration of meetings or calls, offering a baseline for assessing the typical length of interactions.",
      },
      {
        name: "customerInTab",
        widgetIcon: "fa fa-user-circle",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Customer in-tab %",
        addOneWidgetMultipleTimes: true,
        description:
          "The Customer In-Tab% metric measures the percentage of time customers spend actively engaged with the content, indicating their level of attention and interaction during the meeting.",
      },
      {
        name: "slidesShown",
        widgetIcon: "fa fa-images",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Slides shown",
        addOneWidgetMultipleTimes: true,
        description:
          "Slides Shown represents the number of presentation slides displayed during the meeting, offering insights into the depth and scope of the content shared with participants.",
      },
      {
        name: "directShare",
        widgetIcon: "fa fa-share-alt",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Direct share %",
        addOneWidgetMultipleTimes: true,
        description:
          "Direct Share % signifies the proportion of content directly shared with participants, providing a snapshot of how much information is presented firsthand rather than through other means.",
      },
      {
        name: "faceToFace",
        widgetIcon: "fa fa-user-friends",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Face to face %",
        addOneWidgetMultipleTimes: true,
        description:
          "Face to Face % quantifies the portion of the meeting conducted in a direct, personal interaction format, reflecting the degree of interpersonal engagement among participants.",
      },
      {
        name: "screenShare",
        widgetIcon: "fa fa-desktop",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Screen share %",
        addOneWidgetMultipleTimes: true,
        description:
          "Screen Share % illustrates the frequency with which screens are shared during meetings, offering insights into the collaborative and visual aspects of the communication.",
      },
      {
        name: "meetingScore",
        widgetIcon: "fa fa-star",
        width: 2,
        height: 2,
        label: "Meeting score",
        addOneWidgetMultipleTimes: true,
        description:
          "Meeting Score offers a tailored quantitative evaluation, assessing the quality and impact of each participant's contributions in a meeting. It analyzes essential factors like engagement, talk-listen balance, and conversation flow, delivering a personalized measure of the meeting experience for every participant.",
      },
      {
        name: "deals",
        widgetIcon: "fa fa-handshake",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Deals",
        addOneWidgetMultipleTimes: true,
        description:
          "The Deals widget is an interactive tool from your integrations, designed to streamline and enhance the analysis of business deals. It offers comprehensive and customizable options for filtering and viewing deal statuses, making it invaluable for sales teams, business analysts, and decision-makers.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "callOutcomes",
        widgetIcon: "fa fa-phone-volume",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Call outcomes",
        addOneWidgetMultipleTimes: true,
        description:
          "The Call Outcomes widget offers a summary of meeting and call results, categorized by types like successful, failed, and in-progress. It allows users to monitor communication effectiveness and spot trends. Filters for specific teams or users provide a personalized experience, while filtering by call value helps understand interaction quality.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
    ],
  },
  {
    groupLabel: "Voice-based",
    widgets: [
      {
        name: "longestMonologue",
        widgetIcon: "fa fa-comment-dots",
        width: 2,
        height: 2,
        label: "Longest monologues",
        addOneWidgetMultipleTimes: true,
        description:
          "Longest Monologues identifies the instances in which a participant delivers extended, uninterrupted speech during the meeting, highlighting moments of prolonged dialogue.",
      },
      {
        name: "customerStories",
        widgetIcon: "fa fa-book-reader",
        width: 2,
        height: 2,
        label: "Customer stories",
        addOneWidgetMultipleTimes: true,
        description:
          "Customer Stories quantifies the inclusion of narrative elements or testimonials from customers during meetings, showcasing the integration of real-world experiences into presentations.",
      },
      {
        name: "talkListenRatio",
        widgetIcon: "fa fa-balance-scale",
        width: 2,
        height: 2,
        label: "Talk:listen ratio",
        addOneWidgetMultipleTimes: true,
        description:
          "Talk:Listen Ratio assesses the overall balance of communication within the meeting, providing insights into the distribution of speaking roles among participants.",
      },
      {
        name: "conversationShifts",
        widgetIcon: "fa fa-exchange-alt",
        width: 2,
        height: 2,
        label: "Conversation shifts",
        addOneWidgetMultipleTimes: true,
        description:
          "Conversation Shifts indicate the transitions between different topics or themes throughout the meeting, reflecting the dynamic nature of the discussion.",
      },
      {
        name: "avgInterruptions",
        widgetIcon: "fa fa-hand-paper",
        width: 2,
        height: 2,
        label: "Average interruptions",
        addOneWidgetMultipleTimes: true,
        description:
          "Average interruptions tracks instances where participants interrupt each other, providing insights into the conversational flow and potential areas for improvement in communication etiquette.",
      },
    ],
  },
  {
    groupLabel: "Words-based",
    widgets: [
      {
        name: "sentenceStatsKeyword",
        widgetIcon: "fa fa-key",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Keyword",
        addOneWidgetMultipleTimes: true,
        description:
          "Participants select specific keywords to track during meetings, and associated statistics offer insights into the frequency, context, and sentiment surrounding these chosen topics. This focused approach enhances engagement by allowing users to monitor and respond to crucial information relevant to their interests.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
            keywordMentioned: KEYWORD_STATUSES.MENTIONED,
          },
        },
      },
      {
        name: "questions",
        widgetIcon: "fa fa-question-circle",
        width: 2,
        height: 2,
        label: "Questions",
        addOneWidgetMultipleTimes: true,
        description:
          "Questions quantifies the number of questions asked during the meeting, offering a metric for participant engagement and information exchange.",
      },
      {
        name: "wordsPerMinute",
        widgetIcon: "fa fa-tachometer-alt",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Words per minute",
        addOneWidgetMultipleTimes: true,
        description:
          "Words per Minute calculates the average speech rate, providing insights into the pace of communication during the meeting.",
      },
    ],
  },
  {
    groupLabel: "AI insights",
    widgets: [
      {
        name: "sentiment",
        widgetIcon: "fa fa-frown",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Sentiment",
        addOneWidgetMultipleTimes: true,
        description:
          "The Sentiment widget provides a visual representation of the emotional tone of meetings. It captures the dynamics of positive, negative, and neutral sentiments expressed by participants, offering valuable insights into the overall mood and engagement.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsProductIssues",
        widgetIcon: "fa fa-cogs",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Customer product issue",
        addOneWidgetMultipleTimes: true,
        description:
          "This widget identifies and tracks specific challenges customers face with products or services. By showcasing these issues through a single line chart, businesses can prioritize improvements and ensure a seamless customer experience.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "buyer",
            period: "daily",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsPainPoints",
        widgetIcon: "fa fa-user-md",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Customer concern",
        addOneWidgetMultipleTimes: true,
        description:
          "The Customer Concern widget uses a single line chart to depict the evolution of customer concerns over time. It enables businesses to adapt strategies and address emerging issues, ensuring a proactive and customer-centric approach.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "buyer",
            period: "weekly",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsNewNeeds",
        widgetIcon: "fa fa-heart",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Customer needs",
        addOneWidgetMultipleTimes: true,
        description:
          "Tracking potential needs expressed by customers during meetings, the Customer Needs widget helps businesses align their offerings with evolving customer requirements. This insight, presented through a single line chart, informs product development and service strategies.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "buyer",
            period: "weekly",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsPotentialUpselling",
        widgetIcon: "fa fa-arrow-up",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Upsell possibility",
        addOneWidgetMultipleTimes: true,
        description:
          "The Upsell Possibility widget quantifies opportunities for upselling based on customer interactions. It assists sales teams in identifying key moments to introduce additional products or services, thereby maximizing revenue potential.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "weekly",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsFollowUpActions",
        widgetIcon: "fa fa-phone",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Follow-up action",
        addOneWidgetMultipleTimes: true,
        description:
          "The Follow-up Action widget, visualized as a single line chart, outlines post-meeting tasks and commitments. It serves as a roadmap for teams to ensure effective execution of decisions and actions, fostering accountability and timely follow-up.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "weekly",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsBuyerQuestion",
        widgetIcon: "fa fa-search-dollar",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Buyer question",
        addOneWidgetMultipleTimes: true,
        description:
          "Categorized through bar charts, the Buyer Question widget highlights different questions posed by potential buyers. This assists sales teams in tailoring their communication strategies, directly addressing buyer inquiries and enhancing the overall purchasing experience.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "buyer",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsBuyerObjection",
        widgetIcon: "fa fa-times-circle",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Buyer objection",
        addOneWidgetMultipleTimes: true,
        description:
          "Buyer Objection metrics capture instances where potential buyers raise concerns or objections during meetings. These statistics provide insights into the nature and frequency of objections, enabling sales teams to tailor their strategies, address concerns, and improve objection-handling techniques for enhanced sales effectiveness.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "buyer",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsSellerQuestion",
        widgetIcon: "fa fa-comments-dollar",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Seller question",
        addOneWidgetMultipleTimes: true,
        description:
          "Similar to the Buyer Question widget, the Seller Question widget, presented in bar charts, records and categorizes questions raised by sellers. This valuable insight aids in refining training programs, ensuring sellers are well-prepared to respond to buyer inquiries.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "seller",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsTotalQuestion",
        widgetIcon: "fa fa-question",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Total question",
        addOneWidgetMultipleTimes: true,
        description:
          "The Total Question widget, depicted through bar charts, offers a comprehensive view of all questions raised during meetings, encompassing both buyer and seller interactions. It provides a holistic understanding of participant engagement and aids in refining communication strategies.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "weekly",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsTotalObjection",
        widgetIcon: "fa fa-ban",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Total objection",
        addOneWidgetMultipleTimes: true,
        description:
          "The Total Objection widget identifies and quantifies objections raised during meetings from both buyers and sellers. This comprehensive perspective guides businesses in refining objection-handling strategies and enhancing overall sales effectiveness.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "weekly",
            chartType: "period",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsImportance",
        widgetIcon: "fa fa-exclamation-circle",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Importance",
        addOneWidgetMultipleTimes: true,
        description:
          "The Importance widget utilizes a numerical scale (1 to 10) to gauge the perceived importance of statements made during meetings. This metric helps teams focus on key messages that resonate with participants, ensuring impactful communication.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsCustomerSentiment",
        widgetIcon: "fa fa-sad-tear",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Buyer emotions",
        addOneWidgetMultipleTimes: true,
        description:
          "The Buyer Emotions widget categorizes buyer emotions into types such as Neutral, Satisfaction, Dissatisfaction, Doubt, and Trust. This classification provides a nuanced understanding of the emotional journey of buyers, informing strategies to enhance customer experience.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "buyer",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsBuyerPositivity",
        widgetIcon: "fa fa-thumbs-up",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Buyer positivity",
        addOneWidgetMultipleTimes: true,
        description:
          "The Buyer Positivity widget, represented as a single line chart, quantifies positive sentiments expressed by buyers during meetings. This metric serves as a key indicator for measuring and optimizing the overall customer experience, ensuring positive interactions.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "user",
            speakerType: "buyer",
            period: "weekly",
            chartType: "period",
            denominator: "average",
          },
        },
      },
      // HIDDEN TEMPORARILY
      // {
      //   name: "sentenceStatsPromise",
      //   widgetIcon: "fa-solid fa-hand-fingers-crossed",
      //   width: 2,
      //   height: 2,
      //   minWidth: 2,
      //   minHeight: 2,
      //   label: "Promises",
      //   addOneWidgetMultipleTimes: true,
      //   description:
      //     "The Promises widget tracks and categorizes commitments made by your business to customers, giving a detailed overview of promise status throughout the customer journey. This helps businesses uphold commitments, set expectations, and boost overall customer satisfaction.",
      //   options: {
      //     filters: {
      //       groupBy: "user",
      //       speakerType: "buyer",
      //       period: "weekly",
      //       chartType: "period",
      //       denominator: "",
      //     },
      //   },
      // },
      {
        name: "sentenceStatsAsyncPromise",
        widgetIcon: "fa-solid fa-hand-fingers-crossed",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Promises",
        addOneWidgetMultipleTimes: true,
        description:
          "The Promises widget tracks and categorizes commitments made by your business to customers, giving a detailed overview of promise status throughout the customer journey. This helps businesses uphold commitments, set expectations, and boost overall customer satisfaction.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "sentenceStatsAsyncUnprofessionalBehaviour",
        widgetIcon: "fa-regular fa-comment-exclamation",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Unprofessional behaviour",
        addOneWidgetMultipleTimes: true,
        description:
          "Unprofessional Behaviour widget monitors and tracks instances of unprofessional conduct in interactions and meetings. By analyzing trends and patterns, teams can implement corrective measures, develop training programs, and foster a more respectful and professional environment.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "seller",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "playbook",
        widgetIcon: "fa-light fa-book",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Playbook",
        addOneWidgetMultipleTimes: true,
        description: 'Playbook is a comprehensive tool that allows you to monitor the performance of all your playbooks. It provides in-depth analytics and insights, tracking and evaluating the effectiveness of each playbook you use. By highlighting key success metrics and pinpointing areas for improvement, this tool helps you continuously refine and enhance your playbook strategies.',
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      },
      {
        name: "variable",
        widgetIcon: "fa-solid fa-swap-arrows",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Variable",
        addOneWidgetMultipleTimes: true,
        description: 'The Variable widget provides detailed insights into the various variables defined within your account, tracking their values and performance over time. This tool allows you to analyze how different variables impact your processes, helping to identify trends and optimize decision-making based on these key metrics.',
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "value",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
            variableType: "boolean",
          },
        },
      },
      {
        name: "compareWidget",
        widgetIcon: "fa-regular fa-not-equal",
        width: 2,
        height: 2,
        minWidth: 2,
        minHeight: 2,
        label: "Compare two widgets",
        addOneWidgetMultipleTimes: true,
        description:
          "The Compare Widget offers a dynamic visualization tool designed for an in-depth comparative analysis of two distinct widgets' metrics. This analytical instrument enables users to juxtapose statistical data, facilitating a comprehensive understanding of performance trends, efficacy, and outcomes.",
        options: {
          globalFilters: {
            recording_types: [],
            campaign_ids: [],
            global_meeting_types: [],
            global_user_ids: [],
            global_team_ids: [],
            calls_outcome: [],
            deals_status: [],
            global_period: "0",
          },
          filters: {
            groupBy: "",
            speakerType: "",
            period: "",
            chartType: "compare",
            denominator: "",
          },
        },
      }
    ],
  },
  {
    groupLabel: "AI Agents",
    widgets: [],
  }
];

export interface DropdownOption {
  label: String;
  value: String | number;
  id: String | number | null;
}

export const typeOptions: DropdownOption[] = [
  {
    label: "Call",
    value: "call",
    id: 1,
  },
  {
    label: "Meeting",
    value: "meeting",
    id: 2,
  },
];

export const DEFAULT_DASHBOARD = "Default dashboard";

export const customSelectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(0, 0, 0, 0.05)" : "white",
    color: state.isSelected ? "black" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected ? "rgba(0, 0, 0, 0.05)" : "#f7f7f7",
    },
  }),
};

export const defaultDashboardFilters: FilterObject = {
  recordingTypes: [],
  campaigns: [],
  meetingTypes: [],
  filterUsers: [],
  filterTeams: [],
  dealsStatus: [],
  callsOutcome: [],
  startDate: "",
  endDate: "",
  globalPeriod: "",
};
