import { IWidgetLayout } from "../../components/Widgets/utils/IWidget";
import { ITeams, Icurrentaccountselected } from "../interface";
import { FinishedIntegration } from "../settings/Integration/interface";
import {
  EMPTY_DATE_FILTERS,
  FilterActionTypes,
  GET_GLOBAL_FILTERS,
  GET_GLOBAL_FILTERS_ERROR,
  GET_GLOBAL_FILTERS_SUCCESS,
  RESET_FILTERS,
  SET_GLOBAL_FILTERS_LOADING,
  UPDATE_ALL_FILTERS_CAMPAIGN,
  UPDATE_DASHBOARD_FILTERS_AFTER_EDIT, UPDATE_QUERY_BUILDER_FILTER,
} from "./actionTypes";
import { RuleGroupType } from '@react-querybuilder/ts';

export type IRecordingType = {
  id: string;
  name: string;
};

export type ICampaign = {
  id: string;
  name: string;
};

export type IDealStatus = {
  id: string;
  name: string;
};

export type ICallOutcome = {
  id: string;
  name: string;
};

export const NO_MEETING_TYPE = 'No meeting type';

export type IMeetingTypes = {
  id: string;
  name: string;
  parent_id: string | null;
  parent_name: string | null;
  enabled: boolean;
  target_engagement: number;
  target_talk_listen_ratio: number;
  target_shifts_per_minute: number;
  sort_index: number | null;
  teams: null | ITeams[];
};

export type IFilterUser = {
  email: string;
  first_name: string;
  name: string;
  id: number;
  last_login?: Date;
  enabled?: boolean;
  last_name: string;
  mobile_number: string | number;
  profile_picture_url?: string;
  right_level?: string;
  timezone?: string;
  teams?: {
    created_at: Date;
    description: string;
    id: string;
    name: string;
  }[];
  username: string;
  google_drive?: Boolean;
  accounts: Icurrentaccountselected[];
  settings?: any;
  home_layout_settings?: IWidgetLayout[];
  integrations?: FinishedIntegration[];
  is_passive?: boolean;
  language: string | null;
  integration: string | null;
};

export type IFilterTeam = {
  created_at?: Date;
  description: string;
  id: any;
  managers?: Object[];
  name: string;
  settings?: any;
  language: string | null;
  integration: string | null;
};

export interface FilterObject {
  recordingTypes: IRecordingType[];
  campaigns: ICampaign[];
  meetingTypes: IMeetingTypes[];
  filterUsers: IFilterUser[];
  filterTeams: IFilterTeam[];
  filterSession?: string;
  dealsStatus: IDealStatus[];
  callsOutcome: ICallOutcome[];
  startDate: string | null;
  endDate: string | null;
  globalPeriod: string;
  agentIds?: number[];
  queryBuilderObject?: RuleGroupType;
}

export interface GlobalFilterState {
  [key: string]: any;
  loading: boolean;
  isForcedCampaigns: boolean;
  recordingTypes: IRecordingType[];
  forcedCampaigns: ICampaign[];
  campaigns: ICampaign[];
  meetingTypes: IMeetingTypes[];
  filterUsers: IFilterUser[];
  filterTeams: IFilterTeam[];
  dealsStatus: IDealStatus[];
  callsOutcome: ICallOutcome[];
  error: string | Object;
  dashboardFilters: FilterObject;
  meetingFilters: FilterObject;
  recordingFilters: FilterObject;
  statFilters: FilterObject;
  inspirationFilters: FilterObject;
  updateInProgress: boolean;
}

export type getGlobalFilters = {
  type: typeof GET_GLOBAL_FILTERS;
  payload: { accountId: String };
};

export type getGlobalFilterSuccess = {
  type: typeof GET_GLOBAL_FILTERS_SUCCESS;
  payload: any;
};

export type getGlobalFilterError = {
  type: typeof GET_GLOBAL_FILTERS_ERROR;
  payload: string | Object;
};

export type updateFilters = {
  type: FilterActionTypes;
  payload: { filterType: string; value: any };
};

export type emptyDateFilter = {
  type: typeof EMPTY_DATE_FILTERS;
  payload: string;
};

export type updateDashboardFilters = {
  type: typeof UPDATE_DASHBOARD_FILTERS_AFTER_EDIT;
  payload: FilterObject;
}

export type updateCampaignFilter = {
  type: typeof UPDATE_ALL_FILTERS_CAMPAIGN;
  payload: any;
}

export type resetFilters = {
  type: typeof RESET_FILTERS;
}

export type setGlobalFiltersLoading = {
  type: typeof SET_GLOBAL_FILTERS_LOADING;
}

export type updateQueryBuilderObject = {
  type: typeof UPDATE_QUERY_BUILDER_FILTER;
  payload: { filterType: string; queryBuilderObject: RuleGroupType|undefined };
}

export type GlobalFilterAction =
  | getGlobalFilters
  | getGlobalFilterSuccess
  | getGlobalFilterError
  | updateFilters
  | emptyDateFilter
  | updateDashboardFilters
  | updateCampaignFilter
  | resetFilters
  | setGlobalFiltersLoading
  | updateQueryBuilderObject;
