import {
    GET_CALENDAR,
    GET_CALENDAR_ERROR,
    GET_CALENDAR_SUCCESS,
    GET_CALENDAR_UPCOMING_EVENTS,
    GET_CALENDAR_UPCOMING_EVENTS_ERROR,
    GET_CALENDAR_UPCOMING_EVENTS_SUCCESS,
    GET_CALENDAR_PAST_EVENTS,
    GET_CALENDAR_PAST_EVENTS_ERROR,
    GET_CALENDAR_PAST_EVENTS_SUCCESS,
    GET_MEETING_BOTS,
    GET_MEETING_BOTS_ERROR,
    GET_MEETING_BOTS_SUCCESS,
    GET_PROCESSING,
    GET_PROCESSING_ERROR,
    GET_PROCESSING_SUCCESS,
    UPDATE_CALENDAR_UPCOMING_EVENT,
} from "./actionTypes";

import { CalendarsAction, CalendarState } from "./interface";

const initialState: CalendarState = {
    success: "",
    error: [],
    loader: false,
    calendars: null,
    meetingBots: null,
    processing: null,
    upcomingEvents: null,
    upcomingEventsError: '',
    isLoadingUpcomingEvents: false,
    pastEvents: null,
    pastEventsError: '',
    isLoadingPastEvents: false,
}

const Calendar = (state = initialState, action: CalendarsAction) => {
    switch (action.type) {
        case GET_CALENDAR:
            state = {
                ...state,
                success: "",
                error: [],
                calendars: null,
            }
            break;
        case GET_CALENDAR_SUCCESS:
            state = {
                ...state,
                calendars: action.payload,
            }
            break;
        case GET_CALENDAR_ERROR:
            state = {
                ...state,
                error: action.payload,
            }
            break;
        case GET_CALENDAR_UPCOMING_EVENTS:
            state = {
                ...state,
                isLoadingUpcomingEvents: true,
                upcomingEvents: null,
            };
            break;
        case GET_CALENDAR_UPCOMING_EVENTS_SUCCESS:
            state = {
                ...state,
                upcomingEvents: {
                    ...action.payload,
                    data: action.payload.current_page === 1
                      ? action.payload.data  // Reset when on first page
                      : [
                          ...(state.upcomingEvents?.data || []).filter(
                            event => !action.payload.data?.some(newEvent => newEvent.id === event.id)
                          ),
                          ...(action.payload.data || []),  // Append new unique events
                      ],
                },
                isLoadingUpcomingEvents: false,
            };
            break;

        case GET_CALENDAR_UPCOMING_EVENTS_ERROR:
            state = {
                ...state,
                error: action.payload,
                isLoadingUpcomingEvents: false,
            };
            break;
        case GET_CALENDAR_PAST_EVENTS:
            state = {
                ...state,
                isLoadingPastEvents: true,
                pastEvents: null,
            };
            break;
        case GET_CALENDAR_PAST_EVENTS_SUCCESS:
            state = {
                ...state,
                pastEvents: {
                    ...action.payload,
                    data: action.payload.current_page === 1
                      ? action.payload.data  // Reset when on first page
                      : [
                          ...(state.pastEvents?.data || []).filter(
                            event => !action.payload.data.some(newEvent => newEvent.id === event.id)
                          ),
                          ...action.payload.data,  // Append new unique events
                      ],
                },
                isLoadingPastEvents: false,
            };
            break;

        case GET_CALENDAR_PAST_EVENTS_ERROR:
            state = {
                ...state,
                error: action.payload,
                isLoadingPastEvents: false,
            };
            break;
        case UPDATE_CALENDAR_UPCOMING_EVENT:
            const upcomingEventIndex = state.upcomingEvents?.data?.findIndex(
                ({ id }) => id === action.payload.id,
            );

            if (![null, undefined, -1].includes(upcomingEventIndex)) {
                state = {
                    ...state,
                    upcomingEvents: {
                        ...state.upcomingEvents,
                        data: state.upcomingEvents?.data && [
                            ...state.upcomingEvents?.data.slice(0, upcomingEventIndex),
                            {
                                ...state.upcomingEvents?.data[upcomingEventIndex!]!,
                                // @ts-ignore
                                [action.payload.key]: action.payload.value,
                            },
                            ...state.upcomingEvents?.data.slice(upcomingEventIndex! + 1),
                        ],
                    },
                };
            }

            break;
        case GET_MEETING_BOTS:
            state = {
                ...state,
                success: "",
                error: [],
                meetingBots: null,
            }
            break;
        case GET_MEETING_BOTS_SUCCESS:
            state = {
                ...state,
                meetingBots: action.payload,
            }
            break;
        case GET_MEETING_BOTS_ERROR:
            state = {
                ...state,
                error: action.payload,
            }
        case GET_PROCESSING:
            state = {
                ...state,
                success: "",
                error: [],
                processing: null,
            }
            break;
        case GET_PROCESSING_SUCCESS:
            state = {
                ...state,
                processing: action.payload,
            }
            break;
        case GET_PROCESSING_ERROR:
            state = {
                ...state,
                error: action.payload,
            }
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Calendar;
