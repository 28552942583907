import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { has } from 'lodash';

import {
  getAircallTokenError,
  getAircallTokenSuccess,
  createAircallToken as createAircallTokenAction,
  createAircallTokenError,
  createAircallTokenSuccess,
  editAircallToken as editAircallTokenAction,
  editAircallTokenError,
  editAircallTokenSuccess,
  deleteAircallToken as deleteAircallTokenAction,
  deleteAircallTokenError,
  deleteAircallTokenSuccess,
} from "./actions";

import {
  getAircallToken,
  createAircallToken,
  editAircallToken,
  deleteAircallToken,
} from '../../../services/settings/AircallIntegration';

import { ResponseGenerator } from "../../../services/userservice";
import { IAircallToken } from "./interface";
import {
  GET_AIRCALL_TOKEN,
  CREATE_AIRCALL_TOKEN,
  EDIT_AIRCALL_TOKEN,
  DELETE_AIRCALL_TOKEN,
} from "./actionTypes";

function* getAircallTokenHelper() {
  const response: ResponseGenerator = yield call(getAircallToken);
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(getAircallTokenError(error));
    } else {
      yield put(getAircallTokenError('An error has occurred!!'));
    }
  } else {
    yield put(getAircallTokenSuccess(response as unknown as IAircallToken));
  }
}

function* createAircallTokenHelper({
 payload,
}: ReturnType<typeof createAircallTokenAction>) {
  const response: ResponseGenerator = yield call(
    createAircallToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(createAircallTokenError(error));
    } else {
      yield put(createAircallTokenError('An error has occurred!!'));
    }
  } else {
    yield put(createAircallTokenSuccess(response as unknown as IAircallToken));
  }
}

function* editAircallTokenHelper({
 payload,
}: ReturnType<typeof editAircallTokenAction>) {
  const response: ResponseGenerator = yield call(
    editAircallToken,
    payload,
  );
  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(editAircallTokenError(error));
    } else {
      yield put(editAircallTokenError('An error has occurred!!'));
    }
  } else {
    yield put(editAircallTokenSuccess(response as unknown as IAircallToken));
  }
}

function* deleteAircallTokenHelper({
  payload,
}: ReturnType<typeof deleteAircallTokenAction>) {
  const response: ResponseGenerator = yield call(deleteAircallToken, payload);

  if (has(response, 'error') || has(response, 'errors')) {
    const error = response.error || response.errors;
    if (error) {
      yield put(deleteAircallTokenError(error));
    } else {
      yield put(deleteAircallTokenError('An error has occurred!!'));
    }
  } else {
    yield put(deleteAircallTokenSuccess());
  }
}

export function* watchGetAircallTokenHelper() {
  yield takeEvery(GET_AIRCALL_TOKEN, getAircallTokenHelper);
}

export function* watchCreateAircallTokenHelper() {
  yield takeEvery(CREATE_AIRCALL_TOKEN, createAircallTokenHelper);
}

export function* watchEditAircallTokenHelper() {
  yield takeEvery(EDIT_AIRCALL_TOKEN, editAircallTokenHelper);
}

export function* watchDeleteAircallTokenHelper() {
  yield takeEvery(DELETE_AIRCALL_TOKEN, deleteAircallTokenHelper);
}

function* AircallTokenSaga() {
  yield all([fork(watchGetAircallTokenHelper)]);
  yield all([fork(watchCreateAircallTokenHelper)]);
  yield all([fork(watchEditAircallTokenHelper)]);
  yield all([fork(watchDeleteAircallTokenHelper)]);
}

export default AircallTokenSaga;
