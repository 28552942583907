import {
  CREATE_AIRCALL_TOKEN,
  CREATE_AIRCALL_TOKEN_ERROR,
  CREATE_AIRCALL_TOKEN_SUCCESS,
  DELETE_AIRCALL_TOKEN,
  DELETE_AIRCALL_TOKEN_ERROR,
  DELETE_AIRCALL_TOKEN_SUCCESS,
  EDIT_AIRCALL_TOKEN,
  EDIT_AIRCALL_TOKEN_ERROR,
  EDIT_AIRCALL_TOKEN_SUCCESS,
  GET_AIRCALL_TOKEN,
  GET_AIRCALL_TOKEN_ERROR,
  GET_AIRCALL_TOKEN_SUCCESS,
} from './actionTypes';

import { AircallAction, AircallState, } from './interface';
import { createAircallTokenFromData } from './actions';

const initialState: AircallState = {
  error: "",
  aircallToken: null,
  isLoading: false,
}

const AircallIntegration = (state = initialState, action: AircallAction) => {
  switch (action.type) {
    case GET_AIRCALL_TOKEN:
    case CREATE_AIRCALL_TOKEN:
    case EDIT_AIRCALL_TOKEN:
    case DELETE_AIRCALL_TOKEN:
      state = {
        ...state,
        isLoading: true,
      }

      break;
    case GET_AIRCALL_TOKEN_SUCCESS:
    case CREATE_AIRCALL_TOKEN_SUCCESS:
    case EDIT_AIRCALL_TOKEN_SUCCESS:
    case DELETE_AIRCALL_TOKEN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        aircallToken: action.payload ? createAircallTokenFromData(action.payload) : null,
      }

      break;
    case GET_AIRCALL_TOKEN_ERROR:
    case CREATE_AIRCALL_TOKEN_ERROR:
    case EDIT_AIRCALL_TOKEN_ERROR:
    case DELETE_AIRCALL_TOKEN_ERROR:
      state = {
        ...state,
        isLoading: false,
        error: action.payload
      }

      break;
    default:
      state = {...state};
      break;
  }

  return state;
}

export default AircallIntegration;
