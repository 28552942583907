import React, { Component, ReactNode } from 'react';

export class EmojiOutline extends Component<{ children: ReactNode|ReactNode[] }> {
  render() {
    return (
      <span style={{
        backgroundColor: 'black',
        backgroundClip: 'text',
        color: 'transparent',
        cursor: 'default' 
      }}>
        {this.props.children}
      </span>
    );
  }
}
