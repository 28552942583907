import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import MultipleRegister from "./auth/multiple-register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import AccountDetails from './settings/Account/reducer'
import EditPersonalProfileDetails from './auth/edit-personal-profile/reducer'
import getUsersList from './auth/userlist/reducer';
import getAssociatedUsers from "./auth/associated-users/reducer";
import getAssociatedTeams from "./auth/associated-teams/reducer";
import getPresentations from './Presentations/reducer';
import Teams from './settings/Teams/reducer';
import Resources from './settings/Resources/reducer';
import Branding from './settings/Branding/reducer';
import Calendar from "./settings/Calendars/reducer";
import SalesAnalytics from './Analytics/reducer';
import Rights from './Rights/reducer';
import Fonts from './Fonts/reducer';
import Folders from './Folders/reducer';
import Receivers from './Receivers/reducer';
import Files from './Files/reducer';
import Meetings from './Meetings/reducer';
import Rooms from './Rooms/reducer';
import Integration from "./settings/Integration/reducer"
import Registration from "./settings/Registration/reducer"
import SendActivationEmail from "./auth/send-invitation/reducer";
import ClearTeams from "./batch-actions/reducer";
import MeetingTypes from "./settings/MeetingType/reducer";
import Pagination from "./Pagination/reducer";
import Keyword from "./keywords/reducer";
import Snippet from "./snippets/reducer";
import ShareLink from "./share-links/reducer";
import Comment from "./comments/reducer";
import globalFilters from "./GlobalFilters/reducer";
import CallImport from "./CallImports/reducer";
import S2Adversus from "./S2AdversusTokens/reducer";
import SharedWithMe from "./shared-with-me/reducer";
import Chat from "./Chat/reducer";
import AccountTokens from "./settings/AccountTokens/reducer";
import TelavoxIntegration from "./settings/Telavox/reducer";
import DestinyIntegration from "./settings/Destiny/reducer";
import HitrateIntegration from "./settings/Hitrate/reducer";
import AircallIntegration from "./settings/Aircall/reducer";
import AIAgent from './AIAgent/reducer';
import Dashboard from './Dashboard/reducer';
import Variable from './Variable/reducer';

const rootReducer = combineReducers({
  Layout,
  Login,
  Register,
  MultipleRegister,
  ForgetPassword,
  Profile,
  AccountDetails,
  EditPersonalProfileDetails,
  getUsersList,
  getAssociatedUsers,
  getAssociatedTeams,
  getPresentations,
  Teams,
  Resources,
  SalesAnalytics,
  Branding,
  Calendar,
  Rights,
  Fonts,
  Folders,
  Receivers,
  Meetings,
  MeetingTypes,
  Files,
  Rooms,
  Integration,
  Registration,
  SendActivationEmail,
  ClearTeams,
  Pagination,
  Keyword,
  Snippet,
  ShareLink,
  Comment,
  globalFilters,
  CallImport,
  S2Adversus,
  SharedWithMe,
  Chat,
  AccountTokens,
  TelavoxIntegration,
  DestinyIntegration,
  HitrateIntegration,
  AircallIntegration,
  AIAgent,
  Dashboard,
  Variable,
});

export default rootReducer;
