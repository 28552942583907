import React, { Component } from "react"
import { getAudioLevelsForStream } from "./helperFunctions";

interface AudioIndicatorProps {
  stream: MediaStream | null;
}

interface AudioIndicatorState {
  audioLevel: number;
  detachAudioMeter: (() => void) | null;
}

export class AudioIndicator extends Component<AudioIndicatorProps, AudioIndicatorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      audioLevel: 0,
      detachAudioMeter: null,
    };
  }

  componentDidUpdate(prevProps: Readonly<AudioIndicatorProps>): void {
    this.state.detachAudioMeter?.();
    if (this.props.stream && this.props.stream !== prevProps.stream) {
      this.setState({
        detachAudioMeter: getAudioLevelsForStream(this.props.stream, (audioLevel: number) => this.setState({ audioLevel })),
      });
    }
  }

  componentWillUnmount(): void {
    this.state.detachAudioMeter?.();
  }

  render() {
    // Raising to 0.7 power to slightly exaggerate smaller signals
    const displayedAudioLevel = Math.pow(this.state.audioLevel, 0.7) * 100 | 0;
    return this.props.stream ? (
      <div className="audio-level-container">
        <div className="audio-level-indicator" style={{ height: displayedAudioLevel + '%' }}></div>
      </div>
    ) : (
      <></>
    );
  }
}
