import {
  GET_GLOBAL_FILTERS,
  GET_GLOBAL_FILTERS_SUCCESS,
  GET_GLOBAL_FILTERS_ERROR,
  EMPTY_DATE_FILTERS,
  UPDATE_DASHBOARD_FILTERS_AFTER_EDIT,
  RESET_FILTERS,
  SET_GLOBAL_FILTERS_LOADING,
  UPDATE_QUERY_BUILDER_FILTER,
} from "./actionTypes";
import { FilterObject } from "./interface";
import { RuleGroupType } from '@react-querybuilder/ts';
import { DASHBOARD, INSPIRATION, MEETING, RECORDING, STATS } from '../../components/GlobalFilters/utils/constants';
import { FilterMapping } from '../../components/GlobalFilters/utils/interface';

export const getGlobalFilters = (accountId: String) => ({
  type: GET_GLOBAL_FILTERS,
  payload: { accountId },
});

export const getGlobalFiltersSuccess = (data: any) => ({
  type: GET_GLOBAL_FILTERS_SUCCESS,
  payload: data,
});

export const getGlobalFiltersError = (message: string | Object) => ({
  type: GET_GLOBAL_FILTERS_ERROR,
  payload: message,
});

export const updateFilters = (filterType: string, value: any, type: string) => ({
  type: `UPDATE_${type}_FILTERS`,
  payload: { filterType, value },
});

export const emptyDateFilter = (type: string) => ({
  type: EMPTY_DATE_FILTERS,
  payload: type,
});

export const updateDashboardFilters = (filters: FilterObject) => ({
  type: UPDATE_DASHBOARD_FILTERS_AFTER_EDIT,
  payload: filters,
});

export const resetFilters = () => ({
  type: RESET_FILTERS,
});

export const setGlobalFiltersLoading = () => ({
  type: SET_GLOBAL_FILTERS_LOADING,
});

export const updateQueryBuilderObject = (
  filterType: string,
  queryBuilderObject: RuleGroupType|undefined,
) => ({
  type: UPDATE_QUERY_BUILDER_FILTER,
  payload: { filterType, queryBuilderObject },
})

export const findFilterStateKey = (filterOrigin: string): string => {
  const filter: any = {
    DASHBOARD: 'dashboardFilters',
    RECORDING: 'recordingFilters',
  }

  return filter[filterOrigin];
}
