import {
    GET_CALENDAR,
    GET_CALENDAR_SUCCESS,
    GET_CALENDAR_ERROR,
    GET_CALENDAR_UPCOMING_EVENTS,
    GET_CALENDAR_UPCOMING_EVENTS_SUCCESS,
    GET_CALENDAR_UPCOMING_EVENTS_ERROR,
    GET_CALENDAR_PAST_EVENTS,
    GET_CALENDAR_PAST_EVENTS_SUCCESS,
    GET_CALENDAR_PAST_EVENTS_ERROR,
    GET_MEETING_BOTS,
    GET_MEETING_BOTS_ERROR,
    GET_MEETING_BOTS_SUCCESS,
    GET_PROCESSING,
    GET_PROCESSING_ERROR,
    GET_PROCESSING_SUCCESS,
    UPDATE_CALENDAR_UPCOMING_EVENT
} from "./actionTypes";
import {
    ICalendarFilters,
    IMeetingBot,
    IPaginatedUpcomingEventData,
    IPaginatedPastEventData,
} from "./interface";
import { Event } from '../../../pages/Calendars/interface';

export const getCalendar = () => {
    return {
        type: GET_CALENDAR,
        payload: null,
    };
}

export const getCalendarSuccess = (calendars: Event[]) => {
    return {
        type: GET_CALENDAR_SUCCESS,
        payload: calendars,
    }
}

export const getCalendarError = (error: String | Object) => {
    return {
        type: GET_CALENDAR_ERROR,
        payload: error,
    }
}

export const getCalendarUpcomingEvents = (filters?: ICalendarFilters) => {
    return {
        type: GET_CALENDAR_UPCOMING_EVENTS,
        payload: filters,
    };
}

export const getCalendarUpcomingEventsSuccess = (upcomingEvents: IPaginatedUpcomingEventData) => {
    return {
        type: GET_CALENDAR_UPCOMING_EVENTS_SUCCESS,
        payload: upcomingEvents,
    }
}

export const getCalendarUpcomingEventsError = (error: String | Object) => {
    return {
        type: GET_CALENDAR_UPCOMING_EVENTS_ERROR,
        payload: error,
    }
}

export const getCalendarPastEvents = (filters?: ICalendarFilters) => {
    return {
        type: GET_CALENDAR_PAST_EVENTS,
        payload: filters,
    };
}

export const getCalendarPastEventsSuccess = (upcomingEvents: IPaginatedPastEventData) => {
    return {
        type: GET_CALENDAR_PAST_EVENTS_SUCCESS,
        payload: upcomingEvents,
    }
}

export const getCalendarPastEventsError = (error: String | Object) => {
    return {
        type: GET_CALENDAR_PAST_EVENTS_ERROR,
        payload: error,
    }
}

export const updateCalendarUpcomingEvent = (id: string, key: string, value: any) => {
    return {
        type: UPDATE_CALENDAR_UPCOMING_EVENT,
        payload: { id, key, value },
    };
}

export const getMeetingBots = (calendarId: string) => {
    return {
        type: GET_MEETING_BOTS,
        payload: calendarId,
    };
}

export const getMeetingBotsSuccess = (meetingBots: IMeetingBot[]) => {
    return {
        type: GET_MEETING_BOTS_SUCCESS,
        payload: meetingBots,
    }
}

export const getMeetingBotsError = (error: String | Object) => {
    return {
        type: GET_MEETING_BOTS_ERROR,
        payload: error,
    }
}

export const getProcessing = (calendarId: string) => {
    return {
        type: GET_PROCESSING,
        payload: calendarId,
    };
}

export const getProcessingSuccess = (meetingBots: IMeetingBot[]) => {
    return {
        type: GET_PROCESSING_SUCCESS,
        payload: meetingBots,
    }
}

export const getProcessingError = (error: String | Object) => {
    return {
        type: GET_PROCESSING_ERROR,
         payload: error,
    }
}

