import React, { Component } from "react";
import { isEmpty, map, debounce, isEqual } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";

import Recording from "./components/Recording";
import {
  deleteSession,
  getStatsMeetings,
  getStatsMeetingsCount,
  clearRecordingsData,
  getVariables
} from "../../store/actions";
import { IAnalyticsMeetings, IAnalyticsMeetingsCount } from "../../store/Analytics/interface";
import { Icurrentaccountselected } from "../../store/auth/profile/interface";
import EditRecordingModal from "./components/EditRecordingModal";
import {
  editRecordingType,
} from "../../store/settings/MeetingType/actions";
import { DropdownOption } from "../Meetings/interface";
import { IMeetingTypes } from "../../store/GlobalFilters/interface";
import MeetingDealsModal from "./components/MeetingDealsModal";
import InfinitySearch from "../../components/Search/InfinitySearch";
import { renderLoadingIndicator } from "../../components/Widgets/utils/helperFunction";
import MeetingLogsModal from '../Meetings/MeetingLogsModal';

interface RecordingsProps {
  currentaccountselected: Icurrentaccountselected;
  loading: boolean;
  loadingTotal: boolean;
  loadingMeetings: boolean;
  meetings: IAnalyticsMeetings[];
  meetingsCount: IAnalyticsMeetingsCount;
  meetingTypes: IMeetingTypes[];
  globalFilterString: string;
  updateInProgress: boolean;
  getStatsMeetings: (id: String, params?: String) => void;
  getStatsMeetingsCount: (id: String, params?: String) => void;
  clearRecordingsData: () => void;
  deleteSession: (
    accountId: String,
    sessionId: String,
    resolve: any,
    reject: any
  ) => void;
  editRecordingType: (
    accountId: String,
    meetingTypeId: String,
    sessionId: String
  ) => void;
  getVariables: () => void;
}

interface RecordingsState {
  searchByName: string;
  selectedMeeting: IAnalyticsMeetings | null;
  confirmDelete: boolean;
  isEditModalOpen: boolean;
  isDealsModalOpen: boolean;
  meetingToEdit: IAnalyticsMeetings | null;
  shouldFetchMeetings: boolean;
  limit: number;
  filterUpdated: boolean;
  loadingTotalNumberOfRecordings: boolean;
  initialMeetingsLoading: boolean;
  meetingsLoading: boolean;
  meetingForBotLogs?: IAnalyticsMeetings;
  showBotLogsModal: boolean;
}

const DEFAULT_LIMIT: number = 50;

class RecordingsPage extends Component<RecordingsProps, RecordingsState> {
  constructor(props: RecordingsProps) {
    super(props);
    this.state = {
      searchByName: "",
      selectedMeeting: null,
      confirmDelete: false,
      isEditModalOpen: false,
      isDealsModalOpen: false,
      meetingToEdit: null,
      shouldFetchMeetings: false,
      limit: DEFAULT_LIMIT,
      filterUpdated: false,
      loadingTotalNumberOfRecordings: false,
      initialMeetingsLoading: false,
      meetingsLoading: false,
      meetingForBotLogs: undefined,
      showBotLogsModal: false,
    };
  }

  setShouldFetchMeetings = (state: boolean) => {
    this.setState({
      shouldFetchMeetings: state,
    });
  };

  componentDidMount() {
    const {
      currentaccountselected,
      updateInProgress,
      getVariables: getVariablesAction,
    } = this.props;

    getVariablesAction();

    if (!isEmpty(currentaccountselected)) {
      this.setState({
        initialMeetingsLoading: true,
        meetingsLoading: false,
      }, () => {
        if (!updateInProgress) {
          this.fetchRecordings();
          this.handleGetMeetingsCount();
        }
      })
    }
  }

  componentDidUpdate(prevProps: RecordingsProps, prevState: RecordingsState) {
    const { shouldFetchMeetings } = this.state;
    const {
      currentaccountselected,
      globalFilterString,
      updateInProgress,
      clearRecordingsData,
      getVariables: getVariablesAction,
    } = this.props;

    if (
      !isEmpty(currentaccountselected) &&
      (currentaccountselected?.id !== prevProps.currentaccountselected?.id ||
        globalFilterString !== prevProps.globalFilterString)
    ) {
      this.setState({
        limit: DEFAULT_LIMIT,
        filterUpdated: true,
        initialMeetingsLoading: true,
        meetingsLoading: false,
      }, () => {
        if (!updateInProgress) {
          clearRecordingsData();
          this.fetchRecordings();
          this.handleGetMeetingsCount();
        }
      })
    }

    if (!isEqual(prevState.searchByName,this.state.searchByName)) {
      this.setState({
        limit: DEFAULT_LIMIT,
      })
    }

    if (shouldFetchMeetings !== prevState.shouldFetchMeetings && !updateInProgress) {
      this.fetchRecordings();
      this.handleGetMeetingsCount();
      this.setShouldFetchMeetings(false);
    };

    if (prevProps.updateInProgress !== updateInProgress) {
      this.fetchRecordings();
      this.handleGetMeetingsCount();
      this.setShouldFetchMeetings(false);
    }

    if (!isEqual(prevProps.currentaccountselected, currentaccountselected) && currentaccountselected) {
      getVariablesAction();
    }
  }

  handleSearch = (e: any) => {
    const { clearRecordingsData } = this.props;
    this.setState({
      searchByName: e.target.value,
      loadingTotalNumberOfRecordings: true,
      limit: DEFAULT_LIMIT,
    });
    clearRecordingsData();
    this.handleSearchFilter();
  };

  handleSearchFilter = debounce(() => {
    this.fetchRecordings();
    this.handleGetMeetingsCount();

    this.setState({
      loadingTotalNumberOfRecordings: false,
    })
  }, 1500);

  showDeleteConfirmation = (meeting: IAnalyticsMeetings) => {
    this.setState({
      confirmDelete: true,
      selectedMeeting: meeting,
    });
  };

  hideDeleteConfirmation = () => {
    this.setState({
      confirmDelete: false,
      selectedMeeting: null,
    });
  };

  handleDelete = () => {
    const { selectedMeeting } = this.state;
    const { currentaccountselected, deleteSession } = this.props;

    if (!selectedMeeting) return;

    const promise: any = new Promise((resolve, reject) => {
      deleteSession(
        currentaccountselected.id,
        selectedMeeting.session_id,
        resolve,
        reject
      );
    });

    promise
      .then(() => {
        this.fetchRecordings();
        this.handleGetMeetingsCount();
        this.hideDeleteConfirmation();
      })
      .catch(() => {
        this.fetchRecordings();
        this.handleGetMeetingsCount();
        this.hideDeleteConfirmation();
      });
  };

  handleEditMeetingType = (meeting: IAnalyticsMeetings) => {
    this.setState({
      meetingToEdit: meeting,
      isEditModalOpen: true,
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      isEditModalOpen: false,
    });
  };

  handleOpenDealsModal = (meeting: IAnalyticsMeetings) => {
    this.setState({
      meetingToEdit: meeting,
      isDealsModalOpen: true,
    });
  };

  handleCloseDealsModal = () => {
    this.setState({
      isDealsModalOpen: false,
    });
  };

  handleViewLogs = (meeting: IAnalyticsMeetings) => {
    this.setState({
      meetingForBotLogs: meeting,
      showBotLogsModal: true,
    })
  }

  handleCloseBotLogsModal = () => {
    this.setState({
      showBotLogsModal: false,
    })
  }

  handleSaveMeetingType = async (selectedMeetingType: DropdownOption | null) => {
    const { currentaccountselected, editRecordingType } = this.props;
    const { meetingToEdit } = this.state;

    selectedMeetingType &&
      meetingToEdit &&
      await editRecordingType(
        currentaccountselected.id,
        String(selectedMeetingType?.id),
        String(meetingToEdit.session_id)
      );

    this.handleCloseEditModal();
    this.setShouldFetchMeetings(true);
  };

   handleGetMeetingsCount = () => {
    const {
      currentaccountselected,
      globalFilterString,
      getStatsMeetingsCount
    } = this.props;
    const { limit, searchByName } = this.state;

     const encodedSearch = encodeURIComponent(searchByName.trim());
     const globalFilters = `${globalFilterString}&limit=${limit}&search=${encodedSearch}`;

    getStatsMeetingsCount(currentaccountselected.id, globalFilters);
  }

  fetchRecordings() {
    const {
      currentaccountselected,
      globalFilterString,
      getStatsMeetings
    } = this.props;
    const { limit, searchByName } = this.state;

    const encodedSearch = encodeURIComponent(searchByName.trim());
    const globalFilters = `${globalFilterString}&limit=${limit}&search=${encodedSearch}`;

    getStatsMeetings(currentaccountselected.id, globalFilters);
  }

  handleLoadRecordings = (value: string) => {
    let { limit } = this.state;
    const { meetings, meetingsCount } = this.props;
    if (meetings && meetingsCount) {
      if (limit > meetings.length || limit === meetingsCount.total) {
        return;
      }

      limit += DEFAULT_LIMIT;

      this.setState({
        limit,
        initialMeetingsLoading: false,
        meetingsLoading: true
      }, () => this.fetchRecordings());
    }
  };

  render() {
    const {
      currentaccountselected,
      loadingTotal,
      loadingMeetings,
      meetings,
      meetingTypes,
      meetingsCount
    } = this.props;

    const {
      searchByName,
      confirmDelete,
      isEditModalOpen,
      isDealsModalOpen,
      meetingToEdit,
      loadingTotalNumberOfRecordings,
      initialMeetingsLoading,
      meetingsLoading,
      meetingForBotLogs,
      showBotLogsModal,
    } = this.state;

    const formatedCurrentDealOption = meetingToEdit && meetingToEdit.deal
    ? {
        id: meetingToEdit.deal.id,
        label: meetingToEdit.deal.name,
        value: meetingToEdit.deal.name,
      }
    : null;

    const showLoadingTotalNumbers = loadingTotalNumberOfRecordings || (loadingMeetings || loadingTotal);

    const showRecordings = (initialMeetingsLoading && loadingMeetings) || loadingTotalNumberOfRecordings;

    const showData =
      !loadingTotalNumberOfRecordings
      && !loadingMeetings
      && !loadingTotal
      && meetings?.length > 0
      && meetingsCount?.total > 0;

    const noResultsFound =
      !loadingTotalNumberOfRecordings
      && !loadingMeetings
      && !loadingTotal
      && meetingsCount?.total === 0
      && meetings?.length === 0;

    return (
      <>
        <div className="recording-page-content">
          <div className="meeting-sub-header">
            <div className="meeting-sub-header__result-overview">
              {
                showLoadingTotalNumbers ? (
                  <>
                    {renderLoadingIndicator("24")}
                  </>
                ) : showData ? (
                  `Showing ${meetings?.length} results of ${meetingsCount?.total} total`
                ) : noResultsFound ? (
                  "No results found"
                ) : <div className="placeholder-div"></div>
              }
            </div>
          </div>
          <div className="meeting-search">
            <div className="input-group">
              <div className="meeting-search__icon">
                <span className="input-group-text">
                  <i className="fas fa-search" />
                </span>
              </div>
              <input
                className="meeting-search__input"
                type="text"
                placeholder="Search meeting (Participants, Meeting name, company and more)"
                onChange={this.handleSearch}
                value={searchByName}
              />
            </div>
          </div>
          <div className="meeting-list">
            {showRecordings ?
              <div className="meeting-list__loading">
                {renderLoadingIndicator()}
              </div>
              :
              <>
                {map(
                  meetings,
                  (meeting) => (
                    <Recording
                      key={String(meeting.session_id)}
                      meeting={meeting}
                      showDelete={currentaccountselected.right_level === "admin"}
                      onDelete={() => this.showDeleteConfirmation(meeting)}
                      editMeetingType={this.handleEditMeetingType}
                      openDealsModal={this.handleOpenDealsModal}
                      onViewLogs={this.handleViewLogs}
                    />
                  )
                )}
              </>
            }
          </div>
          {confirmDelete && (
            <SweetAlert
              title="Are you sure you want to delete?"
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={this.handleDelete}
              onCancel={this.hideDeleteConfirmation}
            />
          )}

          <EditRecordingModal
            isOpen={isEditModalOpen}
            closeModal={this.handleCloseEditModal}
            meetingTypes={meetingTypes}
            onSave={this.handleSaveMeetingType}
          />

          <MeetingDealsModal
            isOpen={isDealsModalOpen}
            closeModal={this.handleCloseDealsModal}
            currentaccountselected={currentaccountselected}
            receivers={meetingToEdit && meetingToEdit?.participants.flatMap(((participant: { id: string; }) => participant?.id))}
            sessionId={meetingToEdit?.session_id || ''}
            currentDeal={formatedCurrentDealOption}
            shouldFetchMeetings={this.setShouldFetchMeetings}
          />

          {meetingForBotLogs && showBotLogsModal && (
            <MeetingLogsModal
              meetingId={meetingForBotLogs.id?.toString()!}
              onClose={this.handleCloseBotLogsModal}
            />
          )}
          {
            meetings
            && !loadingMeetings
            && !loadingTotal
            && meetings?.length >= DEFAULT_LIMIT
            && !loadingTotalNumberOfRecordings
            && (
              <InfinitySearch
                onPageChange={this.handleLoadRecordings}
              />
            )
          }
          {
            meetings?.length === 0 && !loadingTotalNumberOfRecordings  && !loadingMeetings && !loadingTotal
            && (
              <div className="text-center">No results found</div>
            )
          }
          {
            meetingsLoading && loadingMeetings && !loadingTotalNumberOfRecordings && (
              <div className="meeting-list__loading">
                {renderLoadingIndicator()}
              </div>
            )
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { currentaccountselected } = state.Profile;
  const { loading, loadingTotal, loadingMeetings, meetings, meetingsCount } = state.SalesAnalytics;

  const { filterTeams, filterUsers, meetingTypes, updateInProgress } = state.globalFilters;

  return {
    currentaccountselected,
    loading,
    loadingTotal,
    loadingMeetings,
    meetings,
    meetingsCount,
    teams: filterTeams,
    users: filterUsers,
    meetingTypes,
    updateInProgress,
  };
};

export default connect(mapStateToProps, {
  deleteSession,
  getStatsMeetings,
  getStatsMeetingsCount,
  editRecordingType,
  clearRecordingsData,
  getVariables,
})(RecordingsPage);
