import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

// @ts-ignore
import QueryBuilder from 'react-querybuilder';
import { Controls, Field, Option, RuleGroupType } from '@react-querybuilder/ts';

import './filter-query-builder.css';

import { IVariable } from '../../../store/Variable/interface';
import { EmptyRules } from './constants';
import Select, { MenuPlacement } from 'react-select';
import { isEqual } from 'lodash';

interface FilterQueryBuilderProps {
  variables: IVariable[];
  defaultQuery?: RuleGroupType;
  onQueryChange?: (query: RuleGroupType, fields: Field[]) => void;
}

interface FilterQueryBuilderState {
  fields: Field[];
  query?: RuleGroupType;
}

class FilterQueryBuilder extends Component<FilterQueryBuilderProps, FilterQueryBuilderState> {
  constructor(props: FilterQueryBuilderProps) {
    super(props);
    this.state = {
      fields: [],
      query: props.defaultQuery ?? EmptyRules,
    };
  }

  componentDidMount() {
    this.initializeFields();
  }

  initializeFields = () => {
    const { variables, defaultQuery } = this.props;

    const fields: Field[] = variables.filter(v => v.type === 'boolean').map(variable => {
      const { name, type, extra } = variable;

      const operatorOptions = this.getOperatorOptions(type)

      if (type === 'number') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          inputType: 'number',
        };
      }

      if (type === 'list') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          valueEditorType: 'select',
          values: (extra.list || []).map((value: string) => ({ name: value, label: value })),
        };
      }

      if (type === 'text') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          placeholder: `Enter ${name}`,
        };
      }

      if (type === 'boolean') {
        return {
          uuid: variable.uuid,
          name,
          label: name,
          operators: operatorOptions,
          valueEditorType: 'select',
          values: [
            { name: 'true', label: 'True' },
            { name: 'false', label: 'False' },
          ],
        };
      }

      return {
        uuid: variable.uuid,
        name,
        label: name,
        operators: operatorOptions,
      };
    });

    this.setState({ fields }, () => {
      if (defaultQuery) {
        this.setState({ query: defaultQuery });
      }

      // In case we want one default rule
      //
      //
      // if (this.state.fields[0]) {
      //   const defaultGroup: RuleGroupType = {
      //     combinator: "and",
      //     rules: [
      //       {
      //         combinator: "and",
      //         rules: [
      //           {
      //             field: this.state.fields[0].name,
      //             operator: "Equals",
      //             value: 'true',
      //           },
      //         ],
      //       },
      //     ],
      //   };
      //
      //   this.setState({ query: defaultGroup });
      // }
    })
  };

  getOperatorOptions = (type: string): Option<string>[] => {
    const operatorMap: Record<string, string[]> = {
      number: ['Equals', 'Equals not', 'Less than', 'Greater than'],
      text: ['Equals', 'Equals not', 'Contains'],
      boolean: ['Equals'],
      list: ['Equals', 'Equals not'],
    };

    const operators = operatorMap[type] || ['Is', 'Is not'];

    return operators.map(op => ({ name: op, label: op }));
  };

  handleQueryChange = (updatedQuery: RuleGroupType) => {
    const { onQueryChange } = this.props;
    const { fields, query: currentQuery } = this.state;

    if (isEqual(currentQuery, updatedQuery)) {
      return;
    }

    // Only allow one level of nested operators
    const query = this.restrictNestedGroups(updatedQuery);

    this.setState({
      query,
    }, () => {
      if (onQueryChange) {
        onQueryChange(query, fields);
      }
    })
  }

  restrictNestedGroups = (query: RuleGroupType): RuleGroupType => {
    return {
      ...query,
      rules: query.rules.map(rule => {
        if ('rules' in rule) {
          // If this is a nested group, allow it but prevent deeper nesting
          return {
            ...rule,
            rules: rule.rules.filter(subRule => !('rules' in subRule)),
          };
        }
        return rule;
      }),
    };
  };

  isTopLevel = (path: number[]) => path.length === 0;

  isLastRule = (path: number[]): boolean => {
    const { query } = this.state;

    if (!query || !query.rules || query.rules.length === 0) {
      return false;
    }

    const rulesLength = query.rules.length;

    // Case 1: Exactly three rules -> Only the last rule
    if (rulesLength === 2 || rulesLength === 3) {
      return path[0] === rulesLength - 1;
    }

    // Case 2: More than three rules -> Last rule and second-to-last rule
    if (rulesLength > 3) {
      return path[0] === rulesLength - 1 || path[0] === rulesLength - 2;
    }

    return false;
  };

  getMenuPlacement = (path: number[]): MenuPlacement => {
    return this.isLastRule(path) ? 'top' : 'bottom';
  }

  renderAddGroupAction = ({ path, handleOnClick }: any, isTopLevel: (path: number[]) => boolean) => {
    if (isTopLevel(path)) {
      return (
        <Button
          color="primary"
          onClick={handleOnClick}
          style={{ padding: "5px 10px" }}
        >
          + Add Filter
        </Button>
      );
    }
    return null;
  };

  renderCombinatorSelector = (
    { options, path, value, handleOnChange }: any,
    isTopLevel: (path: number[]) => boolean,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    if (isTopLevel(path)) return null;

    const isDisabled = path[0] === 0;

    const dropdownOptions = options.map((option: any) => ({
      id: option.label,
      value: option.label,
      label: option.label,
    }));

    return (
      <div className="combinator-select">
        <Select
          className="combinator-select-row"
          classNamePrefix="custom-react-querybuilder"
          isDisabled={isDisabled}
          options={dropdownOptions}
          value={dropdownOptions.find((opt: any) => opt.value === value) ?? dropdownOptions[0]}
          onChange={(selectedOption) =>
            handleOnChange(selectedOption ? selectedOption.value : null)
          }
          placeholder="Select Condition"
          isSearchable={false}
          menuPlacement={menuPlacement}
        />
      </div>
    );
  };

  renderFieldSelector = (
    { options, value, handleOnChange }: any,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    const dropdownOptions = options.map((option: any) => ({
      value: option.label,
      label: option.label,
    }));

    return (
      <div className="field-select">
        <Select
          className="field-select-row"
          classNamePrefix="custom-react-querybuilder"
          options={dropdownOptions}
          value={dropdownOptions.find((opt: any) => opt.value === value)}
          onChange={(selectedOption) =>
            handleOnChange(selectedOption ? selectedOption.value : null)
          }
          placeholder="Select Field"
          isSearchable
          menuPlacement={menuPlacement}
        />
      </div>
    );
  };

  renderValueEditor = (
    { fieldData, value, handleOnChange }: any,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    if (fieldData?.valueEditorType === "select" && fieldData?.values) {
      const trueFalseOptions = [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ];

      return (
        <div className="value-select">
          <Select
            className="value-select-row"
            classNamePrefix="custom-react-querybuilder"
            options={trueFalseOptions}
            value={trueFalseOptions.find((opt) => opt.value === value)}
            onChange={(selectedOption) =>
              handleOnChange(selectedOption ? selectedOption.value : null)
            }
            placeholder="Select Value"
            isSearchable={false}
            menuPlacement={menuPlacement}
          />
        </div>
      );
    }
    return null;
  };

  renderOperatorSelector = (
    { options, value, handleOnChange }: any,
    menuPlacement: MenuPlacement = 'bottom',
  ) => {
    const dropdownOptions = options.map((option: any) => ({
      value: option.label,
      label: option.label,
    }));

    return (
      <div className="operator-select">
        <Select
          className="operator-select-row"
          classNamePrefix="custom-react-querybuilder"
          options={dropdownOptions}
          value={dropdownOptions.find((opt: any) => opt.value === value)}
          onChange={(selectedOption) =>
            handleOnChange(selectedOption ? selectedOption.value : null)
          }
          placeholder="Select Operator"
          isSearchable
          menuPlacement={menuPlacement}
        />
      </div>
    );
  };

  renderRemoveGroupAction = ({ handleOnClick }: any) => {
    return (
      <Button
        color="danger"
        onClick={handleOnClick}
        style={{
          padding: "5px 10px",
          margin: "5px",
          fontSize: "12px",
          borderRadius: "6px",
        }}
      >
        ✖ Remove
      </Button>
    );
  };


  render() {
    const { fields, query } = this.state;

    const customControlElements: Partial<Controls> = {
      addRuleAction: () => null,
      addGroupAction: (props) => this.renderAddGroupAction(props, this.isTopLevel),
      combinatorSelector: (props) => {
        return this.renderCombinatorSelector(props, this.isTopLevel, this.getMenuPlacement(props.path));
      },
      fieldSelector: (props) => {
        return this.renderFieldSelector(props, this.getMenuPlacement(props.path));
      },
      valueEditor: (props) => {
        return this.renderValueEditor(props, this.getMenuPlacement(props.path));
      },
      operatorSelector: (props) => {
        return this.renderOperatorSelector(props, this.getMenuPlacement(props.path));
      },
      removeGroupAction: this.renderRemoveGroupAction,
      removeRuleAction: () => null,
    };

    return (
      <div className="filter-query-builder">
        <QueryBuilder
          addRuleToNewGroups
          fields={fields}
          query={query}
          onQueryChange={this.handleQueryChange}
          controlElements={customControlElements}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { variables } = state.Variable;

  return {
    variables,
  };
};

export default connect(mapStateToProps, {})(FilterQueryBuilder);
