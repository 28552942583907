export const GET_AIRCALL_TOKEN = 'GET_AIRCALL_TOKEN';
export const GET_AIRCALL_TOKEN_SUCCESS = 'GET_AIRCALL_TOKEN_SUCCESS';
export const GET_AIRCALL_TOKEN_ERROR = 'GET_AIRCALL_TOKEN_ERROR';
export const CREATE_AIRCALL_TOKEN = 'CREATE_AIRCALL_TOKEN';
export const CREATE_AIRCALL_TOKEN_SUCCESS = 'CREATE_AIRCALL_TOKEN_SUCCESS';
export const CREATE_AIRCALL_TOKEN_ERROR = 'CREATE_AIRCALL_TOKEN_ERROR';
export const EDIT_AIRCALL_TOKEN = 'EDIT_AIRCALL_TOKEN';
export const EDIT_AIRCALL_TOKEN_SUCCESS = 'EDIT_AIRCALL_TOKEN_SUCCESS';
export const EDIT_AIRCALL_TOKEN_ERROR = 'EDIT_AIRCALL_TOKEN_ERROR';
export const DELETE_AIRCALL_TOKEN = 'DELETE_AIRCALL_TOKEN';
export const DELETE_AIRCALL_TOKEN_SUCCESS = 'DELETE_AIRCALL_TOKEN_SUCCESS';
export const DELETE_AIRCALL_TOKEN_ERROR = 'DELETE_AIRCALL_TOKEN_ERROR';
