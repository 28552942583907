import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { History } from "history";
import { cookies } from '../../services/utilities/utilservice'
import { isNull, isEmpty, includes } from "lodash";

import { Icurrentaccountselected, IUser } from "../../store/interface";

import "./top-navbar.scss";
import LoginAsModal from "../VerticalLayout/LoginAsModal";
import img from "../../assets/images/emptyprofilepic.jpeg";
import { superAdminUser } from "../../helpers/common";
import { logoutUser } from "../../services/auth/authservice";
import { getLocalStorage } from "../../services/utilities/utilservice";
import ChatStarter from "../AiChat/ChatStarter";

interface NavbarContentProps {
    menuOpen: boolean;
    users: IUser | null;
    currentAccount: Icurrentaccountselected | null;
    type: String;
    location: any;
    currentuser: IUser;
    isOpenSettingMenu: Boolean;
    isOpenRecordingsMenu: Boolean;
    history: History;
    accountdetails: any;
    settingsMenu: (isSetting: Boolean) => void;
    recordingsMenu: (isRecording: Boolean) => void;
}

interface NavbarContentState {
    isProfileOpen: Boolean;
    isSetMultiUser: Boolean;
    isOpenLoginModal: boolean;
    isSettingsOpen: Boolean;
    isMeetingsOpen: Boolean;
    windowWidth: number;
}

class NavbarContent extends Component<NavbarContentProps, NavbarContentState> {
    constructor(props: NavbarContentProps) {
        super(props);
        this.state = {
            isProfileOpen: false,
            isSetMultiUser: false,
            isOpenLoginModal: false,
            isSettingsOpen: false,
            isMeetingsOpen: false,
            windowWidth: window.innerWidth,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
    }

    async logoutUser() {
        await logoutUser();
        localStorage.clear();
        const ext = window.location.host.split(".").pop();
        if (ext === "io") {
            cookies.remove("JWT_Token", { path: "/", domain: ".pitchflow.io" } as any);
        }
        if (ext === "com") {
            cookies.remove("JWT_Token", { path: "/", domain: ".pitchflow.com" } as any);
        }
    }

    handleOpenLoginModal = (e: any) => {
        e.preventDefault();
        const { isOpenLoginModal } = this.state;
        const hubstaffElement = document.getElementById(
            "hubspot-messages-iframe-container"
        );
        if (hubstaffElement) {
            hubstaffElement.style.setProperty("display", "block", "important");
        }
        this.setState({ isOpenLoginModal: !isOpenLoginModal });
    };

    handleWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    handleGptPromptClick = () => {
        this.props.history.push('/gpt-prompt');
    };

    render() {
        const { users, currentAccount, currentuser } = this.props;
        const { isOpenLoginModal } = this.state;

        const level = !isEmpty(currentAccount) && currentAccount?.right_level!;
        const token = getLocalStorage("isTempAccount");
        const is_beta = getLocalStorage("is_beta") || false;

        return (
            <>
                <ul className="navbar-nav d-flex flex-row">
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            activeClassName="nav-link navbar-item-active"
                            to="/dashboard"
                        >
                            <i className="fa-light fa-chart-simple"></i>
                            <span> Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            activeClassName="nav-link navbar-item-active"
                            to="/meetings"
                        >
                            <i className="far fa-fw fa-calendar-alt"></i>
                            <span> Meetings</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink
                            to="/recordings"
                            className="nav-link"
                            activeClassName="nav-link navbar-item-active"
                        >
                            <i className="fa-regular fa-video"></i>
                            <span> Recordings</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink
                            to="/inspiration-library"
                            className="nav-link"
                            activeClassName="nav-link navbar-item-active"
                        >
                            <i className="fa-regular fa-books"></i>
                            <span> Inspiration library</span>
                        </NavLink>
                    </li>

                    {currentuser?.feature_flags?.add_participants_nonlive && (
                        <li className="nav-item">
                            <NavLink
                                className="nav-link"
                                activeClassName="nav-link navbar-item-active"
                                to="/calendars"
                            >
                                <i className="far fa-fw fa-calendar-alt"></i>
                                <span>Calendars</span>
                            </NavLink>
                        </li>
                    )}

                    {currentAccount?.access_ai_chat_features ?
                        <li>
              <span className="nav-link">
                <ChatStarter/>
              </span>
                        </li>
                        : null}

                    {is_beta && (
                        <li className="nav-item">
                            <NavLink to="/rooms" className="nav-link">
                                <i className="bx bx-bar-chart-alt-2 mr-2"></i>
                                <span> Rooms</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
                <ul className="navbar-nav d-flex flex-row align-items-center">
                    <li className="nav-item">
                        <NavLink
                            to="/settings/integrations"
                            className="nav-link"
                            activeClassName="nav-link navbar-item-active"
                        >
                            <i className="fa-regular fa-plug"></i>
                            <span> Integrations</span>
                        </NavLink>
                    </li>

                    <li className="nav-item dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            style={{ padding: 0 }}
                        >
                            {!isNull(users) && users.profile_picture_url ? (
                                <img
                                    alt="profile-pic"
                                    className="profile-image"
                                    src={users.profile_picture_url}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = img;
                                    }}
                                />
                            ) : !isNull(users) && users.first_name ? (
                                <div className="signle-user">
                                    <p>{users.first_name.charAt(0)}</p>
                                </div>
                            ) : (
                                <img alt="profile-pic" className="profile-image" src={img} />
                            )}
                            <p className="user-name">{!isNull(users) && users.first_name}</p>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {(token === true ||
                                (!isEmpty(currentuser) &&
                                    includes(superAdminUser, currentuser.email))) && (
                                <li>
                                    <Link
                                        className="dropdown-item"
                                        to="#!"
                                        onClick={this.handleOpenLoginModal}
                                    >
                                        Switch Account
                                    </Link>
                                </li>
                            )}
                            {
                                (!isEmpty(currentuser) && includes(superAdminUser, currentuser.email)) && (
                                    <li>
                                        <div
                                            className="dropdown-item"
                                            onClick={this.handleGptPromptClick}
                                            style={{ cursor: "pointer" }}
                                        >
                                            GPT Prompt
                                        </div>
                                    </li>
                                )
                            }
                            <li>
                                <Link
                                    to="/record-live-meeting"
                                    className="dropdown-item"
                                >
                                    Record live meeting
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/profile"
                                    className="dropdown-item"
                                    onClick={() => {
                                        const hubstaff = document.getElementById(
                                            "hubspot-messages-iframe-container"
                                        );
                                        if (hubstaff) {
                                            hubstaff.style.setProperty(
                                                "display",
                                                "block",
                                                "important"
                                            );
                                        }
                                    }}
                                >
                                    Profile
                                </Link>
                            </li>
                            {(level === "admin" || level === "manage_users") && (
                                <li>
                                    <Link
                                        to="/account"
                                        onClick={() => this.props.settingsMenu(true)}
                                        className="dropdown-item"
                                    >
                                        Settings
                                    </Link>
                                </li>
                            )}
                            {/*<li>*/}
                            {/*<Link to="/documents" className="dropdown-item">*/}
                            {/*Documents*/}
                            {/*</Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*  <Link to="/stats" className="dropdown-item">*/}
                            {/*    Stats*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*  <Link to="/insights" className="dropdown-item">*/}
                            {/*    Reports*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                            <li onClick={this.logoutUser.bind(this)}>
                                <a className="dropdown-item" href="#">
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <LoginAsModal
                    isOpenLoginModal={isOpenLoginModal}
                    onOpenLoginModal={this.handleOpenLoginModal}
                    onClose={() => {
                        this.setState({ isOpenLoginModal: false });
                    }}
                    history={this.props.history}
                />
            </>
        );
    }
}

export default NavbarContent;
